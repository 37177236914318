import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../../../Reducers/allCourcesReducer';

// Antd
import { Button, Drawer, Form, Input, Select, notification, Spin, Checkbox } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss';
import { editAssestment, getAllCoursesByRole } from '../../../../../Constants/Api/functions/function';


const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const EditAssessment = ({
    isOpen,
    setOpen,
    handleGetAssessment,
    assessmentData,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const [isTamilEnabled, setIsTamilEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const formRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allCources) {
            handleGetCources();
        }
    }, [allCources])

    useEffect(() => {
        if (assessmentData && formRef.current) {
            formRef.current.setFieldsValue({
                courseId: assessmentData.courseId,
                nameEnglish: assessmentData.nameEnglish,
                discriptionEnglish: assessmentData.discriptionEnglish,
                assessmentURLEnglish: assessmentData.assessmentURLEnglish,
                endDate: assessmentData.endDate,
            });

            if (assessmentData.nameSinhala) {
                setIsSinhalaEnabled(true);
                formRef.current.setFieldsValue({
                    nameSinhala: assessmentData.nameSinhala,
                    discriptionSinhala: assessmentData.discriptionSinhala,
                    assessmentURLSinhala: assessmentData.assessmentURLSinhala,
                });
            }

            if (assessmentData.nameTamil) {
                setIsTamilEnabled(true);
                formRef.current.setFieldsValue({
                    nameTamil: assessmentData.nameTamil,
                    discriptionTamil: assessmentData.discriptionTamil,
                    assessmentURLTamil: assessmentData.assessmentURLTamil,
                });

            }
        }
    }, [assessmentData, formRef, isOpen])

    const handleGetCources = async () => {
        try {
            const res = await getAllCoursesByRole(userProfile.role, userProfile.id);
            if (res) {
                dispatch(setAllCources(res));
            }
        } catch (error) {
            console.error('EditAssessment', error);
        }
    }


    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.id = assessmentData.id;

        setIsLoading(true);

        try {
            const res = await editAssestment(values)
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'Assessment updated successfully'
                );
                setOpen(false);
                await handleGetAssessment();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Failed to update assessment'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Update Assessment"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <div className='drawer-header'>
                    <p>
                        Please select Assessment languages
                    </p>
                    <Checkbox
                        defaultChecked
                        disabled
                    >
                        English
                    </Checkbox>
                    <Checkbox
                        checked={isSinhalaEnabled}
                        onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                    >
                        Sinhala
                    </Checkbox>
                    <Checkbox
                        checked={isTamilEnabled}
                        onChange={(e) => setIsTamilEnabled(e.target.checked)}
                    >
                        Tamil
                    </Checkbox>
                </div>
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {allCources?.map((course, index) => (
                                    <Option
                                        key={index}
                                        value={course.id}
                                    >
                                        {course.nameEnglish}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Name (English)"
                            name="nameEnglish"
                            rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                            <Input
                                placeholder="Please enter assessment name"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description (English)"
                            name="discriptionEnglish"
                            rules={[{
                                required: true,
                                message: 'Please enter assessment description!'
                            }]}>
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The description of the assessment"
                                style={{
                                    height: '100%',
                                    zIndex: 10000,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Assessment URL"
                            name="assessmentURLEnglish"
                            rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                            <Input
                                placeholder="Please enter assessment URL"
                            />
                        </Form.Item>
                        {isSinhalaEnabled && (
                            <>
                                <Form.Item
                                    label="Name (Sinhala)"
                                    name="nameSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                                    <Input
                                        placeholder="Please enter assessment name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Description (Sinhala)"
                                    name="discriptionSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment description!' }]}>
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The description of the assessment"
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Assessment URL (Sinhala)"
                                    name="assessmentURLSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                                    <Input
                                        placeholder="Please enter assessment URL"
                                    />
                                </Form.Item>
                            </>
                        )}

                        {isTamilEnabled && (
                            <>
                                <Form.Item
                                    label="Name (Tamil)"
                                    name="nameTamil"
                                    rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                                    <Input
                                        placeholder="Please enter assessment name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Description (Tamil)"
                                    name="discriptionTamil"
                                    rules={[{ required: true, message: 'Please enter assessment description!' }]}>
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The description of the assessment"
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Assessment URL (Tamil)"
                                    name="assessmentURLTamil"
                                    rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                                    <Input
                                        placeholder="Please enter assessment URL"
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label="End Date"
                            name="endDate"
                            rules={[{
                                required: true,
                                message: 'Please select end date!'
                            }]}>
                            <Input
                                type="date"
                            />
                        </Form.Item>

                        <Button
                            type="primary" htmlType="submit">
                            Update Assessment
                        </Button>

                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditAssessment