import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSessions } from '../../../Constants/Api/functions/function';
import { setAllSessons } from '../../../Reducers/allSessonsReducer';

import { Link } from 'react-router-dom';

// Antd
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';
import { useHistory } from 'react-router-dom';

const Attendance = () => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allSessons = useSelector(state => state.allSessons.allSessons);
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const language = localStorage.getItem('language');
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (userProfile?.role === 'student' || userProfile?.role === 'teacher') {
            history.push(`/dashboard/home`)
        }
    }, [userProfile])

    useEffect(() => {
        if (sessions.length > 0) {
            let data = sessions.map((session, index) => {
                return {
                    key: index,
                    title: language === 'tamil' && session.nameTamil ? session.nameTamil : (language === 'sinhala' && session.nameSinhala ? session.nameSinhala : session.nameEnglish),
                    date: (language === 'tamil' && session.tamilMeeetingDate ? session.tamilMeeetingDate :
                        (language === 'sinhala' && session.sinhalaMeeetingDate ? session.sinhalaMeeetingDate :
                            session.englishMeeetingDate)) || 'N/A',
                    id: session.id
                }
            });
            setTableData(data);
        }
    }, [sessions])


    useEffect(() => {
        if (allSessons === null) {
            handleGetSessions();
        } else {
            let sortedSessons = sortMeetings(allSessons);
            setSessions(sortedSessons);
        }
    }, [allSessons])

    const handleGetSessions = async () => {
        setIsLoading(true);
        try {
            const response = await getSessions(userProfile?.role, userProfile?.id);
            let sortedSessons = sortMeetings(response);
            setSessions(sortedSessons);
            dispatch(setAllSessons(response));
        } catch (error) {
            console.error('handleGetSessions', error);
        }
        setIsLoading(false);
    }

    const sortMeetings = (meetings) => {
        let sortedMeetings = [...meetings];
        sortedMeetings.sort((a, b) => new Date(a.englishMeeetingDate) - new Date(b.englishMeeetingDate));
        return sortedMeetings;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/attendance/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/attendance/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Session Title',
            dataIndex: 'title',
            key: 'title',
            width: '70%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }
    ];

    return (
        <div>
            <div className='header'>
                <h1>
                    Attendance
                </h1>
            </div>
            <div className='table'>
                <Spin spinning={isLoading}>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={{
                            pageSize: 10,
                            position: ["bottomRight"],
                        }}
                    />
                </Spin>
            </div>
        </div>
    )
}

export default Attendance