import React from 'react'

// Antd
import { Avatar, Layout, theme, Tooltip, Space, Button, Select } from 'antd';
import {
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

// Router
import { Link } from 'react-router-dom';

import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from '../../../../Reducers/userProfileReducer';

import male from '../../../../Assets/Images/male.png'
import female from '../../../../Assets/Images/female.png'

const { Header } = Layout;

const items = [
    {
        label:
            <Link to='/dashboard/profile'>
                <Button
                    type='link'
                >
                    <UserOutlined /> View Profile
                </Button>
            </Link>,
        key: '0',
    },
    {
        label: <Button
            type='link'
        >
            <LogoutOutlined /> Sign Out
        </Button>,
        key: '1',
    },
];

const DashboardHeader = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const userProfile = useSelector((state) => state.userProfile.userProfile)
    const language = localStorage.getItem('language');
    const dispatch = useDispatch();

    const handleSignOut = async () => {
        localStorage.removeItem('stemup-space-user')
        dispatch(setUserProfile(null))
        window.location.href = '/login'
    }

    const handleChange = (value) => {
        localStorage.setItem('language', value);
        window.location.reload();
    };

    return (
        <Header
            className='dashboard-header'
            style={{
                padding: 0,
                background: colorBgContainer,
            }}
        >
            <Select
                className='language-selector'
                defaultValue={language || 'english'}
                onChange={handleChange}
                options={[
                    { value: 'english', label: 'English' },
                    { value: 'sinhala', label: 'සිංහල' },
                    // { value: 'tamil', label: 'தமிழ்' },
                ]}
            />
            <Tooltip
                overlayClassName='user-profile-tooltip'
                color='white'
                placement="bottom"
                trigger={['click']}
                title={
                    <Space direction="vertical">
                        {
                            items.map((item) => {
                                return (
                                    <div
                                        key={item.key}
                                        onClick={() => {
                                            if (item.key === '1') {
                                                handleSignOut()
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                    </Space>
                }
            >
                <div className='profile-bar'>
                    <Avatar
                        size="medium"
                        src={userProfile?.gender === 'male' ? male : female}
                    />
                    <p>
                        {userProfile?.name || 'User'}
                    </p>
                </div>
            </Tooltip>
        </Header>
    )
}

export default DashboardHeader