import axios from 'axios';

const { BASE_URL, ORIGIN } = require('../azure/azure');

const checkIsEmailExist = async (email) => {
    try {
        const url = `${BASE_URL}/checkIsEmailExists?email=${email}&origin=${ORIGIN}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('checkIsEmailExist', error);
        return false;
    }
}

const getAllCenters = async () => {
    try {
        const url = `${BASE_URL}/getAllCenters?origin=${ORIGIN}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllCenters', error);
        return false;
    }
}

const addUser = async (user) => {
    try {
        const url = `${BASE_URL}/addUser?origin=${ORIGIN}`;
        const response = await axios.post(url, user);
        return response;
    } catch (error) {
        console.error('addUser', error);
        return false;
    }
}

const signInUser = async (credentials) => {
    try {
        const url = `${BASE_URL}/signinUser?origin=${ORIGIN}`;
        const response = await axios.post(url, credentials);
        return response;
    } catch (error) {
        console.error('signInUser', error);
        return error.response;
    }
}

const requestPasswordReset = async (email) => {
    try {
        const url = `${BASE_URL}/requestPasswordReset?origin=${ORIGIN}&email=${email}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        console.error('requestPasswordReset', error);
        return error.response;
    }
}

const resetPassword = async (resetData) => {
    try {
        const url = `${BASE_URL}/resetPassword?origin=${ORIGIN}`;
        const response = await axios.post(url, resetData);
        return response;
    } catch (error) {
        console.error('resetPassword', error);
        return error.response;
    }
}

const checkForPasswordReset = async (email, Token) => {
    try {
        const url = `${BASE_URL}/checkForPasswordReset?origin=${ORIGIN}&email=${email}&token=${Token}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        console.error('checkForPasswordReset', error);
        return error.response;
    }
}

const getAllUsers = async () => {
    try {
        const url = `${BASE_URL}/getAllUsers?origin=${ORIGIN}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllUsers', error);
        return false;
    }
}

const addCenter = async (center) => {
    try {
        const url = `${BASE_URL}/addCenter?origin=${ORIGIN}`;
        const response = await axios.post(url, center);
        return response;
    } catch (error) {
        console.error('addCenter', error);
        return false;
    }
}

const getCenterById = async (centerId) => {
    try {
        const url = `${BASE_URL}/getCenterById?origin=${ORIGIN}&centerId=${centerId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCenterById', error);
        return false;
    }
}

const getAdminsByCenter = async (centerId) => {
    try {
        const url = `${BASE_URL}/getAdminsByCenter?origin=${ORIGIN}&centerId=${centerId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAdminsByCenter', error);
        return false;
    }
}

const deleteCenter = async (centerId) => {
    try {
        const url = `${BASE_URL}/deleteCenter?origin=${ORIGIN}&centerId=${centerId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteCenter', error);
        return false;
    }
}

const editCenter = async (center) => {
    try {
        const url = `${BASE_URL}/editCenter?origin=${ORIGIN}`;
        const response = await axios.put(url, center);
        return response;
    } catch (error) {
        console.error('editCenter', error);
        return false;
    }
}

const getUsersByCenter = async (centerId) => {
    try {
        const url = `${BASE_URL}/getUsersByCenter?origin=${ORIGIN}&centerId=${centerId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUsersByCenter', error);
        return false;
    }
}

const getGroupsByCenterId = async (centerId) => {
    try {
        const url = `${BASE_URL}/getGroupsByCenterId?origin=${ORIGIN}&centerId=${centerId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getGroupsByCenterId', error);
        return false;
    }
}

const addGroup = async (group) => {
    try {
        const url = `${BASE_URL}/addGroup?origin=${ORIGIN}`;
        const response = await axios.post(url, group);
        return response;
    } catch (error) {
        console.error('addGroup', error);
        return false;
    }
}

const getUsersByGroup = async (groupId) => {
    try {
        const url = `${BASE_URL}/getUsersByGroup?origin=${ORIGIN}&groupId=${groupId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUsersByGroupId', error);
        return false;
    }
}

const addUsersToGroup = async (groupId, userIds) => {
    try {
        const url = `${BASE_URL}/addUsersToGroup?origin=${ORIGIN}&groupId=${groupId}`;
        const response = await axios.post(url, { userIds });
        return response;
    } catch (error) {
        console.error('addUsersToGroup', error);
        return false;
    }
}

const removeUserFromGroup = async (userId, groupId) => {
    try {
        const url = `${BASE_URL}/removeUserFromGroup?origin=${ORIGIN}&userId=${userId}&groupId=${groupId}`;
        const response = await axios.put(url);
        return response;
    } catch (error) {
        console.error('removeUserFromGroup', error);
        return false;
    }
}

const editGroup = async (group) => {
    try {
        const url = `${BASE_URL}/editGroup?origin=${ORIGIN}`;
        const response = await axios.put(url, group);
        return response;
    } catch (error) {
        console.error('editGroup', error);
        return false;
    }
}

const deleteGroup = async (groupId) => {
    try {
        const url = `${BASE_URL}/deleteGroup?origin=${ORIGIN}&groupId=${groupId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteGroup', error);
        return false;
    }
}

const getUserById = async (userId) => {
    try {
        const url = `${BASE_URL}/getUserById?origin=${ORIGIN}&id=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUserById', error);
        return false;
    }
}

const deleteUser = async (userId) => {
    try {
        const url = `${BASE_URL}/deleteUser?origin=${ORIGIN}&userId=${userId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteUser', error);
        return false;
    }
}

const editUser = async (user) => {
    try {
        const url = `${BASE_URL}/editUser?origin=${ORIGIN}`;
        const response = await axios.put(url, user);
        return response;
    } catch (error) {
        console.error('editUser', error);
        return false;
    }
}

const createUser = async (user) => {
    try {
        const url = `${BASE_URL}/createUser?origin=${ORIGIN}`;
        const response = await axios.post(url, user);
        return response;
    } catch (error) {
        console.error('createUser', error);
        return false;
    }
}

const getAllCoursesByRole = async (role) => {
    try {
        const url = `${BASE_URL}/getAllCoursesByRole?origin=${ORIGIN}&role=${role}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllCoursesByRole', error);
        return false;
    }
}

const addCourse = async (course) => {
    try {
        const url = `${BASE_URL}/addCourse?origin=${ORIGIN}`;
        const response = await axios.post(url, course);
        return response;
    } catch (error) {
        console.error('addCourse', error);
        return false;
    }
}

const getCourseContentByRoleAndCourseId = async (courseId, role, userId) => {
    try {
        const url = `${BASE_URL}/getCourseContentByRoleAndCourseId?origin=${ORIGIN}&role=${role}&courseId=${courseId}&userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCourseContentByRoleAndCourseId', error);
        return false;
    }
}

const deleteCourse = async (courseId) => {
    try {
        const url = `${BASE_URL}/deleteCourse?origin=${ORIGIN}&courseId=${courseId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteCourse', error);
        return false;
    }
}

const getCourseById = async (courseId) => {
    try {
        const url = `${BASE_URL}/getCourseById?origin=${ORIGIN}&courseId=${courseId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCourseById', error);
        return false;
    }
}

const editCourse = async (course) => {
    try {
        const url = `${BASE_URL}/editCourse?origin=${ORIGIN}`;
        const response = await axios.put(url, course);
        return response;
    } catch (error) {
        console.error('editCourse', error);
        return false;
    }
}

const addCourseContent = async (content) => {
    try {
        const url = `${BASE_URL}/addCourseContent?origin=${ORIGIN}`;
        const response = await axios.post(url, content);
        return response;
    } catch (error) {
        console.error('addCourseContent', error);
        return false;
    }
}

const editCourseContent = async (content) => {
    try {
        const url = `${BASE_URL}/editCourseContent?origin=${ORIGIN}`;
        const response = await axios.put(url, content);
        return response;
    } catch (error) {
        console.error('editCourseContent', error);
        return false;
    }
}

const deleteCourseContent = async (contentId) => {
    try {
        const url = `${BASE_URL}/deleteCourseContent?origin=${ORIGIN}&contentId=${contentId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteCourseContent', error);
        return false;
    }
}

const addSession = async (session) => {
    try {
        const url = `${BASE_URL}/addSession?origin=${ORIGIN}`;
        const response = await axios.post(url, session);
        return response;
    } catch (error) {
        console.error('addSession', error);
        return false;
    }
}

const getSessions = async (role, userId) => {
    try {
        const url = `${BASE_URL}/getSessions?origin=${ORIGIN}&role=${role}&userId=${userId}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error('getSessions', error);
        return false;
    }
}

const deleteSession = async (id) => {
    try {
        const url = `${BASE_URL}/deleteSession?origin=${ORIGIN}&id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteSession', error);
        return false;
    }
}

const editSession = async (session) => {
    try {
        const url = `${BASE_URL}/editSession?origin=${ORIGIN}`;
        const response = await axios.put(url, session);
        return response;
    } catch (error) {
        console.error('editSession', error);
        return false;
    }
}

const addNote = async (note) => {
    try {
        const url = `${BASE_URL}/addNote?origin=${ORIGIN}`;
        const response = await axios.post(url, note);
        return response;
    } catch (error) {
        console.error('addNote', error);
        return false;
    }
}

const getNotes = async (role) => {
    try {
        const url = `${BASE_URL}/getNotes?origin=${ORIGIN}&role=${role}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error('getNotes', error);
        return false;
    }
}

const deleteNote = async (id) => {
    try {
        const url = `${BASE_URL}/deleteNote?origin=${ORIGIN}&id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteNote', error);
        return false;
    }
}

const enrollToCourseContent = async (courseId, userId, contentId) => {
    try {
        const url = `${BASE_URL}/enrollToCourseContent?origin=${ORIGIN}&courseId=${courseId}&userId=${userId}&contentId=${contentId}`;
        const response = await axios.post(url);
        return response;
    } catch (error) {
        console.error('enrollToCourseContent', error);
        return false;
    }
}

const getProgressByUserId = async (userId) => {
    try {
        const url = `${BASE_URL}/getProgressByUserId?origin=${ORIGIN}&userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getProgressByUserId', error);
        return false;
    }
}

const addAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/addAssestment?origin=${ORIGIN}`;
        const response = await axios.post(url, values);
        return response;
    } catch (error) {
        console.error('addAssestment', error);
        return false;
    }
}

const getAssestmentsByRole = async (role, id) => {
    try {
        const url = `${BASE_URL}/getAssestmentsByRole?origin=${ORIGIN}&role=${role}&id=${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAssestmentsByRole', error);
        return false;
    }
}

const getAssementByIdAndRole = async (id, role, urerId) => {
    try {
        const url = `${BASE_URL}/getAssementByIdAndRole?origin=${ORIGIN}&id=${id}&role=${role}&userId=${urerId}`;
        const response = await axios.get(url);
        return response.data[0]
    } catch (error) {
        console.error('getAssementByIdAndRole', error);
        return false;
    }
}

const deleteAssestment = async (id) => {
    try {
        const url = `${BASE_URL}/deleteAssestment?origin=${ORIGIN}&id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteAssestment', error);
        return false;
    }
}

const editAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/editAssestment?origin=${ORIGIN}`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('editAssestment', error);
        return false;
    }
}

const getSubmissionsByAssestment = async (id) => {
    try {
        const url = `${BASE_URL}/getSubmissionsByAssestment?origin=${ORIGIN}&id=${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getSubmissionsByAssestment', error);
        return false;
    }
}

const deleteAssessmentSubmission = async (id) => {
    try {
        const url = `${BASE_URL}/deleteAssessmentSubmission?origin=${ORIGIN}&id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteAssessmentSubmission', error);
        return false;
    }
}

const reviewAssestmentSubmission = async (values) => {
    try {
        const url = `${BASE_URL}/reviewAssestmentSubmission?origin=${ORIGIN}`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('reviewAssestmentSubmission', error);
        return false;
    }
}

const attemptToAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/attemptToAssestment?origin=${ORIGIN}`;
        const response = await axios.post(url, values);
        return response;
    } catch (error) {
        console.error('attemptToAssestment', error);
        return false;
    }
}

const editAttempt = async (values) => {
    try {
        const url = `${BASE_URL}/editAttempt?origin=${ORIGIN}`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('editAttempt', error);
        return false;
    }
}

const exportAllUsers = async () => {
    try {
        const url = `${BASE_URL}/exportAllUsers?origin=${ORIGIN}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('exportAllUsers', error);
        return false;
    }
}

const attendToSession = async (sessionId, userId) => {
    try {
        const url = `${BASE_URL}/attendToSession?origin=${ORIGIN}&sessionId=${sessionId}&userId=${userId}`;
        const response = await axios.post(url);
        return response;
    } catch (error) {
        console.error('attendToSession', error);
        return false;
    }
}

const getAttendanceBySession = async (sessionId) => {
    try {
        const url = `${BASE_URL}/getAttendanceBySession?origin=${ORIGIN}&sessionId=${sessionId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAttendanceBySession', error);
        return false;
    }
}

const getSessionById = async (sessionId) => {
    try {
        const url = `${BASE_URL}/getSessionById?origin=${ORIGIN}&sessionId=${sessionId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getSessionById', error);
        return false;
    }
}

const getCentersByAdminId = async (adminId) => {
    try {
        const url = `${BASE_URL}/getCentersByAdminId?origin=${ORIGIN}&adminId=${adminId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCentersByAdminId', error);
        return false;
    }
}

const getUsersByAdminId = async (adminId) => {
    try {
        const url = `${BASE_URL}/getUsersByAdminId?origin=${ORIGIN}&adminId=${adminId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUsersByAdminId', error);
        return false;
    }
}

export {
    checkIsEmailExist,
    getAllCenters,
    addUser,
    signInUser,
    requestPasswordReset,
    resetPassword,
    checkForPasswordReset,
    getAllUsers,
    addCenter,
    getCenterById,
    getAdminsByCenter,
    deleteCenter,
    editCenter,
    getUsersByCenter,
    getGroupsByCenterId,
    addGroup,
    getUsersByGroup,
    addUsersToGroup,
    removeUserFromGroup,
    editGroup,
    deleteGroup,
    getUserById,
    deleteUser,
    editUser,
    createUser,
    getAllCoursesByRole,
    addCourse,
    getCourseContentByRoleAndCourseId,
    deleteCourse,
    getCourseById,
    editCourse,
    deleteCourseContent,
    addCourseContent,
    editCourseContent,
    addSession,
    getSessions,
    deleteSession,
    editSession,
    addNote,
    getNotes,
    deleteNote,
    enrollToCourseContent,
    getProgressByUserId,
    addAssestment,
    getAssestmentsByRole,
    getAssementByIdAndRole,
    deleteAssestment,
    editAssestment,
    getSubmissionsByAssestment,
    deleteAssessmentSubmission,
    reviewAssestmentSubmission,
    attemptToAssestment,
    editAttempt,
    exportAllUsers,
    attendToSession,
    getAttendanceBySession,
    getSessionById,
    getCentersByAdminId,
    getUsersByAdminId,
}