import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, List, Progress, message, Spin } from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';

// API
import { enrollToCourseContent, getCourseContentByRoleAndCourseId } from '../../../../Constants/Api/functions/function';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import nodata from '../../../../Assets/Images/nodata.png';
import video from '../../../../Assets/Images/video.png';
import files from '../../../../Assets/Images/file.png';

import './style.scss';


const Course = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEnrollLoading, setIsEnrollLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const courseId = useParams().id
    const [selectedContentId, setSelectedContentId] = useState();
    const language = localStorage.getItem('language');
    const [courseContents, setCourseContents] = useState();
    const [completedPercentage, setCompletedPercentage] = useState(0);
    const [data, setData] = useState()
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        getCourseContents();
    }, [])


    const getCourseContents = async () => {
        setIsLoading(true);
        try {
            let response = await getCourseContentByRoleAndCourseId(courseId, userProfile.role, userProfile.id);
            if (response) {
                setCourseContents(response);

                response.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });

                const completedContents = response.filter(item => item?.participation?.id);
                const percentage = (completedContents.length / response.length) * 100;
                setCompletedPercentage(percentage % 1 === 0 ? percentage : percentage.toFixed(1));

                setData(response.map((item, index) => {
                    return {
                        id: item.id,
                        title: language === 'tamil' && item.titleTamil ? item.titleTamil : (language === 'sinhala' && item.titleSinhala ? item.titleSinhala : item.titleEnglish),
                        status: item?.participation?.id ? 'completed' : 'pending',
                        duration: item.duration
                    }
                }))

                const uncompletedContent = response.find(item => !item?.participation?.id);
                if (uncompletedContent) {
                    setSelectedContentId(uncompletedContent.id);
                } else {
                    setSelectedContentId(response[0]?.id);
                }
            }
        } catch (error) {
            console.error('getCourseContents', error);
        }
        setIsLoading(false);
    }

    const handleMarkAsLearned = async () => {
        setIsEnrollLoading(true);
        try {
            const response = await enrollToCourseContent(courseId, userProfile.id, selectedContentId);
            if (response && response.status === 200) {
                openMessage('success', 'Marked as learned');
                getCourseContents();
            } else {
                openMessage('error', 'Error occured while marking as learned');
            }
        } catch (error) {
            console.error('handleMarkAsLearned', error);
            openMessage('error', 'Error occured while marking as learned');
        }
        setIsEnrollLoading(false);
    }

    const openMessage = (type, content) => {
        messageApi.open({
            type: type,
            content: content,
        });
    };

    return (
        <div className='course'>
            {contextHolder}
            <Spin
                spinning={isLoading}
                tip="Loading..."
                size="large">
                <div className='course-continer'>
                    {userProfile?.role === 'superadmin' &&
                        <div className='course-header'>
                            <Link to={`/dashboard/cources/${courseId}/edit`}>
                                <Button type='primary' className='btn'>
                                    <EditOutlined />
                                    <span>Edit Course</span>
                                </Button>
                            </Link>
                        </div>}

                    {(isLoading || !courseContents?.length)
                        ?
                        <div className='no-data'>
                            {!isLoading &&
                                <div>
                                    <img
                                        src={nodata}
                                        alt='No Data' />
                                    <h1>
                                        {language === 'sinhala' ? 'පාඨමාලාව ඇතුළත් කර නොමැත' : language === 'tamil' ? 'பாடநெறி சேர்க்கப்படவில்லை' : 'Course Content Will be Available Soon'}
                                    </h1>
                                </div>}
                        </div>
                        :
                        <>
                            <div className='body'>
                                <div className='course-left'>
                                    <h1 className='content-title'>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                if (!selectedContent) return '';

                                                if (language === 'sinhala') {
                                                    return selectedContent.titleSinhala || selectedContent.titleEnglish;
                                                } else if (language === 'tamil') {
                                                    return selectedContent.titleTamil || selectedContent.titleEnglish;
                                                } else {
                                                    return selectedContent.titleEnglish;
                                                }
                                            })()
                                        }
                                    </h1>
                                    {
                                        (() => {
                                            const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                            if (!selectedContent) return <h1>
                                                {language === 'sinhala' ? 'වීඩියෝ නොමැත' : language === 'tamil' ? 'வீடியோ இல்லை' : 'No video available'}
                                            </h1>;

                                            let videoURL = '';
                                            if (language === 'sinhala') {
                                                videoURL = selectedContent.videoURLSinhala || selectedContent.videoURLEnglish;
                                            } else if (language === 'tamil') {
                                                videoURL = selectedContent.videoURLTamil || selectedContent.videoURLEnglish;
                                            } else {
                                                videoURL = selectedContent.videoURLEnglish;
                                            }

                                            return videoURL ? (
                                                <iframe
                                                    src={videoURL}
                                                    title="YouTube video player"
                                                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                                                </iframe>
                                            )
                                                :
                                                <div className='no-data-mini'>
                                                    {!isLoading &&
                                                        <div>
                                                            <img
                                                                src={video}
                                                                alt='No Data' />
                                                            <h1>
                                                                {language === 'sinhala' ? 'වීඩියෝ නොමැත' : language === 'tamil' ? 'வீடியோ இல்லை' : 'Video Will be Available Soon'}
                                                            </h1>
                                                        </div>
                                                    }
                                                </div>
                                        })()
                                    }
                                    <h2>{language === 'sinhala' ? 'මෙම පාඨමාලා පිළිබඳව' : language === 'tamil' ? 'இந்த பாடநெறி பற்றி' : 'About This Lesson'}</h2>
                                    <p>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);

                                                if (!selectedContent) return '';

                                                if (language === 'sinhala') {
                                                    return selectedContent.shortDiscriptionSinhala || selectedContent.shortDiscriptionEnglish;
                                                } else if (language === 'tamil') {
                                                    return selectedContent.shortDiscriptionTamil || selectedContent.shortDiscriptionEnglish;
                                                } else {
                                                    return selectedContent.shortDiscriptionEnglish;
                                                }
                                            })()
                                        }
                                    </p>

                                    <h2>{language === 'sinhala' ? 'පාඨමාලා ලේඛන' : language === 'tamil' ? 'பாட ஆவணங்கள்' : 'Course Materials'}</h2>
                                    <div className='drive-floder'>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                if (!selectedContent) return
                                                <h1>
                                                    {language === 'sinhala' ? 'ගූගල් ෆෝල්ඩරය නොමැත' : language === 'tamil' ? 'கூகுள் படையெடு இல்லை' : 'No Drive Folder available'}
                                                </h1>;

                                                let driveURL = '';
                                                if (language === 'sinhala') {
                                                    driveURL = selectedContent.driveURLSinhala || selectedContent.driveURLEnglish;
                                                } else if (language === 'tamil') {
                                                    driveURL = selectedContent.driveURLTamil || selectedContent.driveURLEnglish;
                                                } else {
                                                    driveURL = selectedContent.driveURLEnglish;
                                                }

                                                return driveURL ? (
                                                    <iframe
                                                        className='drive-iframe'
                                                        title="Google Drive Folder"
                                                        src={driveURL}>
                                                    </iframe>
                                                ) : <div className='no-data-mini'>
                                                    {!isLoading &&
                                                        <div>
                                                            <img
                                                                src={files}
                                                                alt='No Data' />
                                                            <h1>
                                                                {language === 'sinhala' ? 'ගූගල් ෆෝල්ඩරය නොමැත' : language === 'tamil' ? 'கூகுள் படையெடு இல்லை' : 'Files Will be Available Soon'}
                                                            </h1>
                                                        </div>
                                                    }
                                                </div>
                                            })()
                                        }
                                    </div>

                                    {(userProfile?.role === 'student' || userProfile?.role === 'teacher') &&
                                        <div className='course-footer'>
                                            <Button
                                                loading={isEnrollLoading}
                                                type='primary'
                                                onClick={handleMarkAsLearned}
                                                className={
                                                    courseContents?.find(item => item.id === selectedContentId)?.participation?.id ? 'completed' : ''
                                                }
                                                disabled={courseContents?.find(item => item.id === selectedContentId)?.participation?.id}
                                            >
                                                {
                                                    courseContents?.find(item => item.id === selectedContentId)?.participation?.id
                                                        ? (language === 'sinhala' ? 'පිලිබඳ ලකුණු කර ඇත' : language === 'tamil' ? 'முடிந்ததாகக் குறி' : 'Completed')
                                                        : (language === 'sinhala' ? 'සම්පූර්ණ කළ ලෙස ලකුණු කරන්න' : language === 'tamil' ? 'முடிந்ததாகக் குறி' : 'Mark As Completed')
                                                }
                                            </Button>
                                        </div>}
                                </div>

                                <div className='course-right'>
                                    <div className='progress'>
                                        <h2>
                                            <p>
                                                {language === 'sinhala' ? 'ඔබගේ අධ්‍යාපන ප්‍රගතිය' : language === 'tamil' ? 'உங்கள் கற்றல் முன்னேற்றம்' : 'Your Study Progress'}
                                            </p>
                                            <span>{
                                                (userProfile?.role === 'student' || userProfile?.role === 'teacher') ? completedPercentage + '%' : '100%'
                                            }
                                            </span>
                                        </h2>
                                        <div className='progress-bar'>
                                            <Progress
                                                percent={
                                                    (userProfile?.role === 'student' || userProfile?.role === 'teacher') ? completedPercentage : 100
                                                }
                                                status="active"
                                                strokeColor={{
                                                    from: '#108ee9',
                                                    to: '#87d068',
                                                }}
                                            />
                                        </div>
                                        <p>
                                            {language === 'sinhala' ? 'අධ්‍යාපනය පිළිබඳව ඔබගේ ප්‍රගතිය පිළිබඳව ප්‍රතිපලයක් ලබා ගත හැකිය' : language === 'tamil' ? 'படித்தது பற்றி உங்கள் முன்னேற்றம் உங்களுக்கு பின்னால் பதிலளிக்கலாம்' : 'The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt'}
                                        </p>
                                    </div>
                                    <div className='course-content'>
                                        <h2>{language === 'sinhala' ? 'පාඨමාලා අන්තර්ගතය' : language === 'tamil' ? 'பாடநெறி உள்ளடக்கம்' : 'Course Contents'}</h2>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={data}
                                            renderItem={item =>
                                                <List.Item
                                                    className={selectedContentId === item.id ? 'active' : ''}
                                                    onClick={() => setSelectedContentId(item.id)}
                                                >
                                                    <div className='btn' type='link'>
                                                        {selectedContentId === item.id
                                                            ?
                                                            <PauseRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                            :
                                                            <PlayArrowRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='course-content-item'>
                                                        <p>{item.title}</p>
                                                        <span>{item.duration}</span>
                                                    </div>
                                                    <div className='status'>
                                                        {
                                                            item?.status === 'completed'
                                                                ?
                                                                <div className='icon'>
                                                                    <CheckRoundedIcon style={{
                                                                        fontSize: '1.2rem',
                                                                        color: '#fff'
                                                                    }} />
                                                                </div>
                                                                :
                                                                <div
                                                                    className='icon not'
                                                                >
                                                                    <CheckRoundedIcon style={{
                                                                        fontSize: '1.2rem',
                                                                        color: '#fff'
                                                                    }} />
                                                                </div>
                                                        }
                                                    </div>
                                                </List.Item>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </Spin>
        </div>
    )
}

export default Course