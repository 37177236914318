import React from 'react'

import {
  Button,
} from 'antd'
import {
  EnvironmentTwoTone,
  PhoneTwoTone,
  MailTwoTone,
} from '@ant-design/icons';

// Reveal
import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles.sass'
import './Slider.scss'
import './button.scss'


import HomeHeroImg from './Assets/homeHero.png'
import LearnfromAnyware from './Assets/LearnfromAnyware.png'

import Videos from './Assets/video.png'
import Tutes from './Assets/tutes.png'
import Cost from './Assets/cost.png'

import Exam from './Assets/Exam.png'
import Contact from './Assets/contact.jpg'

import logo from '../../Assets/Logos/logo.png'
import history from '../../../history';

AOS.init();
AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 500,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});


const Landing = () => {
  return (
    <div className='landing-page' id='landinghome'>
      <header>
        <div className='header-nav-web'>
          <div className='logo'>
            <a href='/'>
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className='btns'>
            <Button
              type='link'
              className='signup'
              onClick={() => {
                window.location.href = '/register';
              }}
            >
              Register
            </Button>
            <Button
              type='primary'
              className='signin'
              onClick={
                () => {
                  window.location.href = "/login";
                  history.push('/login');
                }
              }
            >
              Sign In
            </Button>
          </div>
        </div>
      </header>


      <div className='body'>

        <div
          className='main-hero'>
          <h1 data-aos="fade-up">
            Welcome to <h5>STEMUP Spaces Portal</h5>
          </h1>
          <h3 data-aos="fade-up">
            STEMUP Spaces offer an innovative approach to connect school students and Volunteers, bridging the gap between them in a unique manner.
          </h3>
          <div data-aos="fade-up" className='hero-btns'>
            <button
              className="learn-more"
              onClick={() => {
                window.location.href = "/login";
                history.push('/login');
              }}
            >
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span
                className="button-text"
              >
                Sign In
              </span>
            </button>
          </div>
          <div data-aos="zoom-in" className='hero-img'>
            <img src={HomeHeroImg} alt='hero-img' />
          </div>
        </div>

        <div className='section sesction-one' id='scroll'>
          <h2 data-aos="fade-up" >
            How to use this platform?
          </h2>

          <div className='video-holder' data-aos="fade-up">
            <iframe src="https://www.youtube.com/embed/8DgUukAhzHc?si=yuWfjAkZ6NKediGh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>

        {/* <div className='section section-two flex-cont' id='landingabout'>
          <div className='text left'
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <h2 id="start">
              Multilingual Content Accessibility
            </h2>
            <h3>
              STEMUP Spaces proudly offers diverse content in Sinhala, English, and Tamil, promoting inclusive learning experiences. Whether you're comfortable in Sinhala, English, or Tamil, our platform ensures you have access to quality educational resources tailored to your language preference, fostering a more effective and personalized learning journey.
            </h3>
          </div>
          <div className='image right'
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img src={LearnfromAnyware} alt='section-two-img' />
          </div>
        </div> */}

        {/* <div className='section section-three flex-cont'>
          <div className='image left'
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              style={{
                marginTop: '20px'
              }}
              src={Exam} alt='section-two-img' />
          </div>
          <div className='text right'
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <h2 id="start">
              Seamless Meeting Integration
            </h2>
            <h3>
              Experience effortless collaboration with STEMUp Spaces. Joining meetings is a breeze, connecting you instantly with educators and peers. Engage in real-time discussions, ask questions, and participate actively in your learning process. Our platform is designed to make virtual meetings easy, enhancing your overall educational experience with dynamic interaction and engagement.
            </h3>
          </div>
        </div> */}

        {/* <div className='section section-four' id='landingclesses'>
          <h2 data-aos="zoom-in">
            Affordable and flexible pricing plans.
          </h2>
          <h3 data-aos="fade-up">
            Pricing plans that suits your company.
          </h3>

          <div className='section-four-cards'>

            <div className='card' data-aos="fade-up">
              <div className='card-content'>
                <h1>Just $1 per user</h1>
                <p>per month</p>
                <h2>(1 - 100 users)</h2>

                <h3>Starter Plan</h3>
                <ul>
                  <li>
                    Mange up to 100 interns.
                  </li>
                  <li>
                    50 monthly evaluations.
                  </li>
                  <li>
                    10 mentors.
                  </li>
                </ul>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-content'>
                <h1>Just $2 per user</h1>
                <p>per month</p>
                <h2>(100 - 250 users)</h2>

                <h3>Standard Plan</h3>
                <ul>
                  <li>
                    Mange up to 250 interns.
                  </li>
                  <li>
                    100 monthly evaluations.
                  </li>
                  <li>
                    20 mentors.
                  </li>
                  <li>
                    10% discount on annual payment.
                  </li>
                </ul>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-content'>
                <h1>Just $4 per user</h1>
                <p>per month</p>
                <h2>(250+ users)</h2>

                <h3>Pro Plan</h3>
                <ul>
                  <li>
                    Mange up to 500 interns.
                  </li>
                  <li>
                    200 monthly evaluations.
                  </li>
                  <li>
                    50 mentors.
                  </li>
                  <li>
                    All premium evaluation templates.
                  </li>
                  <li>
                    20% discount on annual payment.
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div> */}


        {/* <div
          style={{
            backgroundImage: `url(${Contact})`,
          }}
          className='section section-six'
          data-aos="fade-up"
          id='landingcontact'
        >
          <h2>
            Let's connect and grow together.
          </h2>
          <h3>
            <a
              href='/'
            >
              Contact Us
            </a>
          </h3>
        </div> */}

      </div>

      <footer>
        <div className='footer-sec'>
          <div className='logo'>
            <a href='/'>
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className='text'>
            <p>
              Explore STEMUP Spaces and unlock a world of limitless learning possibilities. Engage with interactive content, connect with peers and educators, and track your progress seamlessly. Join us in shaping the future of tech education!
            </p>
          </div>
        </div>

        <div className='footer-sec'>
          <h3>
            Contacts
          </h3>
          <div className='text'>
            <p>
              <span><EnvironmentTwoTone /></span>
              No:67/2, Wewalduwa, Kelaniya, 11600, Sri Lanka
            </p>
            <p>
              <span><PhoneTwoTone /></span>+94 721 811 811
            </p>
            <p>
              <span><MailTwoTone /></span>
              <a href='/' >
                info@stemup.lk
              </a>
            </p>
          </div>
        </div>

      </footer>
      <section className='copy-right'>
        <p>
          © 2024
          <a
            href='https://stemup.lk/'
            target='_blank'
            rel='noreferrer'
          > STEMUP Educational Foundation.</a> All RIghts Reserved.
        </p>
      </section>
    </div>
  )
}

export default Landing