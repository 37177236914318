import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

// Antd
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Spin, Table, notification } from 'antd';
import { DeleteOutlined } from '@mui/icons-material';
import { removeUserFromGroup } from '../../../../../../../Constants/Api/functions/function';


const GroupMembers = ({ members, handleGetMembers }) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const searchInput = useRef(null);


    useEffect(() => {
        if (members?.length) {
            const data = members?.map((user, index) => {
                return {
                    key: index + 1,
                    id: user?.id,
                    name: user?.name,
                    Role: user?.role,
                    email: user?.email,
                }
            })
            setTableData(data)
        }
    }, [members])

    const handleRemoveUserFromGroup = async (userId) => {
        setIsLoading(true)
        try {
            await removeUserFromGroup(userId, members[0]?.groupId);
            handleGetMembers();
            removeUsersNotification("success")
        } catch (error) {
            console.log(error)
            removeUsersNotification("error", error)
        } finally {
            setIsLoading(false)
        }
    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const removeUsersNotification = (type, error) => {
        api[type]({
            message: type === "success" ?
                "Users Removed"
                : "Error Removing User",
            description: type === "success"
                ? "User has been removed successfully."
                : `There was an error removing user. Please try again later. ${error}`
        });
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
            width: '30%',
            sorter: (a, b) => a.key - b.key,
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Remove User from Group?"
                        description="Are you sure you want to remove this user from the group?"
                        onConfirm={() => handleRemoveUserFromGroup(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            loading={isLoading}
                            type='link'
                            style={{
                                color: 'red',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <DeleteOutlined /> Remove
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const stuedntsColumns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
            width: '20%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '60%',
            ...getColumnSearchProps('email'),
        },
    ];

    return <div
        className='users'>
        {contextHolder}
        <Spin
            spinning={isLoading}
            tip="Loading Users..."
        >
            <Table
                columns={(userProfile?.role === "student" || userProfile?.role === 'teacher') ? stuedntsColumns : columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 10,
                    position: ["bottomRight"],
                }}
                scroll={window.innerWidth <= 768 && { x: 1000 }}
            />
        </Spin>
    </div>;
};
export default GroupMembers;