import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Spin,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// API
import { getUserById, signInUser } from '../../Constants/Api/functions/function';

// Redux
import { useDispatch } from 'react-redux';
import { setUserProfile } from "../../Reducers/userProfileReducer";

// Assets
import MathsBg from '../../Assets/Images/MathsBg.jpg'
import LearnImg from '../../Assets/Images/signin.png'
import logo from '../../Assets/Logos/logo.png'

import './style.scss'


const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('stemup-space-user'))
        if (user) {
            successModal()
            handleGetUser(user.id)
        }
    }, [])

    const onFinish = async (values) => {
        await handleUserLogin(values)
    };

    const handleGetUser = async (id) => {
        setIsLoading(true)
        const response = await getUserById(id)
        dispatch(setUserProfile(response))
        window.location.href = '/dashboard'
        setIsLoading(false)
    }

    const handleUserLogin = async (user) => {
        setIsLoading(true)
        const response = await signInUser(user)
        setIsLoading(false)

        if (response.status === 200) {
            successModal()

            dispatch(setUserProfile(response.data))

            localStorage.setItem('stemup-space-user', JSON.stringify(response.data))

            setTimeout(() => {
                window.location.href = '/dashboard'
            }, 1000);

        } else if (response.status === 404) {
            userNotFoundModal()
        } else if (response.status === 400) {
            incrrectPasswordModal()
        } else {
            errorModal()
        }
    }

    const successModal = () => {
        Modal.success({
            content: 'You have successfully signed in. You will be redirected to the dashboard in a few seconds.',
            className: 'register-modal',
        });
    }

    const errorModal = () => {
        Modal.error({
            title: 'Error',
            content: 'An error occurred while signing in. Please try again.',
            className: 'register-modal',
        });
    }

    const userNotFoundModal = () => {
        Modal.error({
            title: 'User not found | පරිශීලකයා හමු නොවිය | பயனர் காணப்படவில்லை',
            content: 'Please check the email address and try again. | ඊ-මේල් ලිපිනය පරීක්ෂා කරන්න | மின்னஞ்சல் முகவரி சரிபார்க்கவும்',
            className: 'register-modal',
        });
    }

    const incrrectPasswordModal = () => {
        Modal.error({
            title: 'Incorrect Password | මුරපදය වැරදියි | கடவுச்சொல் தவறானது',
            content: 'Please check the password and try again. | මුරපදය පරීක්ෂා කරන්න | கடவுச்சொல் சரிபார்க்கவும்',
            className: 'register-modal',
        });
    }

    return (
        <div className='signin-page'>
            <section className="bg-img">
                <div className="overlay">
                    <img src={MathsBg} alt="Maths Background" />
                </div>
                <div className="content">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>

                    <h1>Welcome to</h1>
                    <span>STEMUP SPACES</span>

                    <h2>
                        A place where you can learn and grow.
                    </h2>

                    <div className="learn-img">
                        <img src={LearnImg} alt="" />
                    </div>

                    <p className="quote">
                        "Education is the most powerful weapon which you can use to change the world"
                    </p>
                    <p className="by">
                        - Nelson Mandela -
                    </p>
                </div>
            </section>
            <section className="form">
                <div className="form-container">
                    <div className="logo-mobile">
                        <img src={logo} alt="" />
                    </div>
                    <h1 className="title">
                        Sign in to your account
                    </h1>
                    <Spin spinning={isLoading}>
                        <Form
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                className='form-item'
                                label="Email Address | ඊ-මේල් ලිපිනය | மின்னஞ்சல் முகவரி"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email address!',
                                    },
                                    {
                                        pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <Input placeholder="sampleuser@gmail.com" />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                name="password"
                                label="Password | මුරපදය | கடவுச்சொல்"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Input password"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>

                            <Form.Item className='form-bottom'>
                                <Button
                                    className='form-button'
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Log in
                                </Button>

                                <Button
                                    className='fogot-button'
                                    type="link"
                                    onClick={() => window.location.href = '/forgot-password'}
                                >
                                    Fogot Password?
                                </Button>
                            </Form.Item>

                            <div className="sign-up">
                                <h1>Don't have an account? &nbsp;
                                    <a href="/register">Sign up</a></h1>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </section>
        </div>
    )
}
export default Login