import React, { useEffect, useState } from 'react';

// Antd
import {
    PlusOutlined,
} from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Input,
    List,
    Radio,
    Spin
} from 'antd';
import { Link } from 'react-router-dom';

// API
import { getAllCoursesByRole } from '../../../Constants/Api/functions/function';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../Reducers/allCourcesReducer';

// Styles
import './style.scss';

import AddCource from './AddCource';

const Cources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAddCourseOpen, setIsAddCourseOpen] = useState(false);
    const [radioValue, setRadioValue] = useState(1);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const language = localStorage.getItem('language');
    const [data, setData] = useState();
    const [coursesData, setCoursesData] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            const sortedData = [...data].sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });

            setCoursesData(
                sortedData.map((item) => {
                    return {
                        id: item.id,
                        title: language === 'tamil' && item.nameTamil ? item.nameTamil : (language === 'sinhala' && item.nameSinhala ? item.nameSinhala : item.nameEnglish),
                        discription: language === 'tamil' && item.shortDiscriptionTamil ? item.shortDiscriptionTamil : (language === 'sinhala' && item.shortDiscriptionSinhala ? item.shortDiscriptionSinhala : item.shortDiscriptionEnglish),
                        isPublished: item.isPublished,
                        thumbnail: language === 'tamil' && item.coverImageURLTamil ? item.coverImageURLTamil : (language === 'sinhala' && item.coverImageURLSinhala ? item.coverImageURLSinhala : item.coverImageURLEnglish),
                        visibleFor: item.visibleFor
                    };
                })
            );
        }
    }, [data]);

    useEffect(() => {
        if (allCources) {
            setData(allCources);
        } else {
            hadleGetCources();
        }
    }, [allCources]);

    const hadleGetCources = async () => {
        setIsLoading(true);
        if (userProfile) {
            const response = await getAllCoursesByRole(userProfile.role);
            if (response) {
                setData(response);
                dispatch(setAllCources(response));
            }
        }
        setIsLoading(false);
    }

    function trimDescription(description) {
        const words = description.split(' ');
        if (words.length > 40) {
            return words.slice(0, 40).join(' ') + '...';
        }
        return description;
    }

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
        if (e.target.value === 1) {
            setData(allCources);
        } else {
            const filteredData = allCources?.filter((item) => {
                return item.nameEnglish.toLowerCase().includes(e.target.value === 2 ? 'arduino' : e.target.value === 3 ? 'web' : 'python');
            });
            setData(filteredData);
        }
    }

    return <div className='cources'>
        <div className='cources-action-buttons'>
            <div className='filters'>
                <div className='filter-search'>
                    <h2>
                        {language === 'tamil' ? 'அனைத்து பாடங்கள்' : language === 'sinhala' ? 'සියලුම පාඨමාලා' : 'All Courses'}
                    </h2>
                    <div className='filter-inputs'>
                        <AutoComplete
                            popupMatchSelectWidth={252}
                            options={allCources?.map((item) => {
                                return {
                                    value: item.nameEnglish,
                                    label: item.nameEnglish,
                                }
                            })}
                            size="large"
                            onSelect={(value) => {
                                if (value) {
                                    const filteredData = allCources?.filter((item) => {
                                        return item.nameEnglish.toLowerCase().includes(value.toLowerCase());
                                    });
                                    setData(filteredData);
                                } else {
                                    setData(allCources);
                                }
                            }}
                        >
                            <Input.Search size="large"
                                placeholder={language === 'tamil' ? "தலைப்பு மூலம் தேடுக" : language === 'sinhala' ? "මාතෘකාවේ හොඳමාලා සොයන්න" : "Search by title"}
                                enterButton
                                onSearch={(value) => {
                                    if (value) {
                                        const filteredData = allCources?.filter((item) => {
                                            return item.nameEnglish.toLowerCase().includes(value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    } else {
                                        setData(allCources);
                                    }
                                }}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setData(allCources);
                                    } else {
                                        const filteredData = allCources?.filter((item) => {
                                            return item.nameEnglish.toLowerCase().includes(e.target.value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    }
                                }}
                            />
                        </AutoComplete>
                    </div>
                </div>
                <div className='radio-buttons'>
                    <Radio.Group onChange={onChangeRadio} value={radioValue}>
                        <Radio value={1}>
                            All
                        </Radio>
                        <Radio value={2}>
                            Arduino
                        </Radio>
                        <Radio value={3}>
                            Web Development
                        </Radio>
                        <Radio value={4}>
                            Python
                        </Radio>
                    </Radio.Group>
                </div>
            </div>
            {userProfile?.role === 'superadmin' &&
                <Button
                    type='primary'
                    onClick={() => setIsAddCourseOpen(true)}
                >
                    <PlusOutlined />
                    Add Cource
                </Button>}
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Courses..."
        >
            <div className='cources-body'>
                <Spin
                    tip="Loading..."
                    spinning={isLoading}
                >
                    <List
                        grid={{
                            gutter: 30,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={coursesData}
                        renderItem={(item) => (
                            <List.Item>
                                <Link to={`/dashboard/cources/${item.id}`}>
                                    <div className='course-card'>
                                        <div className='card-image-container'>
                                            <img
                                                src={
                                                    item?.thumbnail
                                                        ? item?.thumbnail
                                                        : require('./1695793881259.jpeg')
                                                } alt=""
                                            />
                                            <div className='cource-card-header-buttons'>
                                                <div
                                                    className='duration'
                                                    style={{
                                                        backgroundColor: item?.isPublished ? '#4CAF50' : '#f44336'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            item?.isPublished ? 'Published' : 'Not Published'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') &&
                                                <div className={
                                                    `course-visiblity
                                                    ${item?.visibleFor === 'all' ? 'all'
                                                        : item?.visibleFor === 'students' ? 'students'
                                                            : 'teachers'
                                                    }`
                                                }
                                                >
                                                    <p>
                                                        {
                                                            `This course is visible for
                                                        ${item?.visibleFor === 'all' ? 'Everyone' : item?.visibleFor === 'students' ? 'Students' : 'Teachers'}`
                                                        }
                                                    </p>
                                                </div>}
                                        </div>
                                        <div className='card-body'>
                                            <h3>
                                                {item?.title}
                                            </h3>
                                            <p>
                                                {trimDescription(item?.discription)}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </List.Item>
                        )}
                    />
                </Spin>
            </div>
        </Spin>

        <AddCource
            isOpen={isAddCourseOpen}
            setOpen={setIsAddCourseOpen}
            hadleGetCources={hadleGetCources}
        />
    </div>;
};
export default Cources;