import React, { useEffect, useState } from 'react'

import {
    Button,
    List,
    Modal,
    Form,
    Row,
    Col,
    Spin,
    message,
    Divider,
    Popconfirm,
    notification,
    Checkbox,
    Tabs,
    Select,
    Tag
} from 'antd'
import {
    PlusOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../Reducers/allCourcesReducer';
import { setAllSessons } from '../../../Reducers/allSessonsReducer';

// API 
import { addNote, attendToSession, deleteNote, deleteSession, getAllCoursesByRole, getNotes, getSessions } from '../../../Constants/Api/functions/function';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss'
import AddSession from './AddSession';
import EditSession from './EditSession';
import { setNote } from '../../../Reducers/noteReducer';

import male from '../../../Assets/Images/male.png'
import female from '../../../Assets/Images/female.png'


const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const { Option } = Select;

const Home = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [api, contextHolderNoti] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false)
    const [loadingNote, setLoadingNote] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isAddSessionOpen, setIsAddSessionOpen] = useState(false)
    const [isEditSessionOpen, setIsEditSessionOpen] = useState(false)
    const [selectedSession, setSelectedSession] = useState()
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const allSessons = useSelector(state => state.allSessons.allSessons);
    const [selectedNoteTab, setSelectedNoteTab] = useState('teachers');
    const note = useSelector(state => state.note.note);
    const language = localStorage.getItem('language');
    const [cources, setCources] = useState();
    const [sessions, setSessions] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allCources) {
            hadleGetCources();
        } else {
            setCources(allCources);
        }
    }, [allCources])

    useEffect(() => {
        if (allSessons === null) {
            handleGetSessions();
        } else {
            let sortedSessons = sortMeetings(allSessons);
            setSessions(sortedSessons);
        }
    }, [allSessons])

    useEffect(() => {
        if (!note) {
            handleGetNote();
        }
    }, [note])

    const hadleGetCources = async () => {
        if (userProfile && userProfile.role === 'superadmin') {
            try {
                const response = await getAllCoursesByRole('superadmin');
                dispatch(setAllCources(response));
                setCources(response);
            } catch (error) {
                console.error('hadleGetCources', error);
            }
        }
    }

    const handleGetNote = async () => {
        setLoadingNote(true);
        try {
            const response = await getNotes(userProfile?.role);
            dispatch(setNote(response));
        } catch (error) {
            console.error('handleGetNote', error);
        }
        setLoadingNote(false);
    }

    const handleDeleteNote = async () => {
        setLoadingNote(true);
        try {
            const response = await deleteNote(`NOTE-${selectedNoteTab}`)
            if (response && response.status === 200) {
                openNotification('success',
                    'Success',
                    'Note deleted successfully'
                );
                await handleGetNote();
            } else {
                openNotification('error',
                    'Error',
                    response?.data?.message
                );
            }
        } catch (error) {
            console.error('handleDeleteNote', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setLoadingNote(false);
    }

    const handleGetSessions = async () => {
        setIsLoading(true);
        try {
            const response = await getSessions(userProfile?.role, userProfile?.id);
            let sortedSessons = sortMeetings(response);
            setSessions(sortedSessons);
            dispatch(setAllSessons(response));
        } catch (error) {
            console.error('handleGetSessions', error);
        }
        setIsLoading(false);
    }

    const sortMeetings = (meetings) => {
        let sortedMeetings = [...meetings];
        if (language === 'tamil') {
            sortedMeetings.sort((a, b) => {
                if (a.tamilMeeetingDate && b.tamilMeeetingDate) {
                    return new Date(a.tamilMeeetingDate) - new Date(b.tamilMeeetingDate);
                } else {
                    return new Date(a.englishMeeetingDate) - new Date(b.englishMeeetingDate);
                }
            });
        } else if (language === 'sinhala') {
            sortedMeetings.sort((a, b) => {
                if (a.sinhalaMeeetingDate && b.sinhalaMeeetingDate) {
                    return new Date(a.sinhalaMeeetingDate) - new Date(b.sinhalaMeeetingDate);
                } else {
                    return new Date(a.englishMeeetingDate) - new Date(b.englishMeeetingDate);
                }
            });
        } else {
            sortedMeetings.sort((a, b) => new Date(a.englishMeeetingDate) - new Date(b.englishMeeetingDate));
        }
        return sortedMeetings;
    }

    const handleDeleteSession = async (id) => {
        setIsLoading(true);
        try {
            const response = await deleteSession(id);
            if (response && response.status === 200) {
                openNotification('success',
                    'Success',
                    'Session deleted successfully'
                );
                await handleGetSessions();
            } else {
                openNotification('error',
                    'Error',
                    response?.data?.message
                );
            }
        } catch (error) {
            console.error('handleDeleteSession', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setIsLoading(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinishNote = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        setLoadingNote(true);

        try {
            const res = await addNote(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New note added successfully'
                );
                setIsModalOpen(false);
                await handleGetNote();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setLoadingNote(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const handleJoinMeeting = async (item) => {
        window.open(
            language === 'tamil' && item.tamilMeeetingURL ? item.tamilMeeetingURL : (language === 'sinhala' && item.sinhalaMeeetingURL ? item.sinhalaMeeetingURL : item.englishMeeetingURL),
            '_blank'
        );

        if (item.tamilMeeetingURL || item.sinhalaMeeetingURL || item.englishMeeetingURL) {
            await handleAttendance(item);
        }
    }

    const copyMeetingLink = async (item) => {
        if (item.tamilMeeetingURL || item.sinhalaMeeetingURL || item.englishMeeetingURL) {
            await handleAttendance(item);
        }

        navigator.clipboard.writeText(
            language === 'tamil' && item.tamilMeeetingURL ? item.tamilMeeetingURL : (language === 'sinhala' && item.sinhalaMeeetingURL ? item.sinhalaMeeetingURL : item.englishMeeetingURL)
        )
        messageApi.open({
            type: 'success',
            content: 'Meeting link copied to clipboard',
        });
    }

    const handleAttendance = async (item) => {
        if (userProfile?.role === 'student' || userProfile?.role === 'teacher') {
            if (!item?.participation?.id) {
                try {
                    const response = await attendToSession(item.id, userProfile?.id);
                    if (response && response.status === 200) {
                        messageApi.open({
                            type: 'success',
                            content: 'Attendance marked successfully',
                        });
                        await handleGetSessions();
                    }
                } catch (error) {
                    console.error('handleAttendance', error);
                }
            }
        }
    }

    return (
        <div className='home'>
            {contextHolder}
            {contextHolderNoti}
            <div className='home-left-top'>
                <div className='bg-img'>
                </div>
                <div className='profile-img'>
                    <img
                        src={userProfile?.gender === 'male' ? male : female}
                        alt='profile'
                    />
                </div>
                <div className='profile-name'>
                    <h1>
                        {userProfile?.name}
                    </h1>
                    <h2>
                        {userProfile?.email}
                    </h2>
                </div>
            </div>
            <div className='home-continer'>
                <div className='home-left'>
                    <div className='updates-body'>
                        <div className='sessions-header'>
                            <h1>
                                {language === 'tamil' ? 'உங்கள் அமர்வு காலவரிகள்' : language === 'sinhala' ? 'ඔබගේ meeting කාල සටහන' :
                                    'Your Sessions Timeline'}
                            </h1>
                            {userProfile?.role === 'superadmin' &&
                                <Button
                                    onClick={() => setIsAddSessionOpen(true)}
                                    type='primary'
                                    className='add-btn'
                                >
                                    <PlusOutlined /> Add Session
                                </Button>}
                        </div>
                        <Spin spinning={isLoading} tip="Please wait...">
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={sessions}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <div className='session-header'>
                                                        <p className='meet-title'
                                                            style={{
                                                                width: userProfile?.role === 'superadmin' ? '70%' : '100%'
                                                            }}
                                                        >
                                                            {language === 'tamil' && item.nameTamil ? item.nameTamil : (language === 'sinhala' && item.nameSinhala ? item.nameSinhala : item.nameEnglish)}
                                                        </p>
                                                        {userProfile?.role === 'superadmin' &&
                                                            <div className='actions'>
                                                                <Button
                                                                    type='link'
                                                                    size='small'
                                                                    className='edit-btn'
                                                                    onClick={() => {
                                                                        setSelectedSession(item);
                                                                        setIsEditSessionOpen(true);
                                                                    }}
                                                                >
                                                                    <EditOutlined />
                                                                </Button>
                                                                <Popconfirm
                                                                    title="Are you sure to delete this session?"
                                                                    onConfirm={() => handleDeleteSession(item.id)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        danger
                                                                        type='link'
                                                                        size='small'
                                                                        className='delete-btn'
                                                                    >
                                                                        <DeleteOutlined />
                                                                    </Button>
                                                                </Popconfirm>

                                                                {
                                                                    item?.isVisible ? <Tag color="green">Visible</Tag> : <Tag color="red">Not Visible</Tag>
                                                                }
                                                            </div>}
                                                        {(userProfile?.role === 'student' || userProfile?.role === 'teacher') &&
                                                            <div className='attendance'>
                                                                {item.participation?.id ? <span className='attended'> Attended</span> : <span className='not-attended'> Not Attended</span>}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                description={
                                                    <div className='meeting-data'>
                                                        <p>
                                                            <ReactQuill
                                                                className='session-description'
                                                                readOnly
                                                                theme="bubble"
                                                                value={language === 'tamil' && item.discriptionTamil ? item.discriptionTamil : (language === 'sinhala' && item.discriptionSinhala ? item.discriptionSinhala : item.discriptionEnglish)}
                                                            />
                                                        </p>

                                                        <Divider />
                                                        <div className='meeting-date-time'>
                                                            {(language === 'tamil' && item.tamilMeeetingDate ? item.tamilMeeetingDate :
                                                                (language === 'sinhala' && item.sinhalaMeeetingDate ? item.sinhalaMeeetingDate :
                                                                    item.englishMeeetingDate)) ?
                                                                <div className='date-continer'>
                                                                    <div>
                                                                        <span>
                                                                            <CalendarOutlined />
                                                                        </span>
                                                                        <p className='id'>
                                                                            {
                                                                                (language === 'tamil' && item.tamilMeeetingDate ? item.tamilMeeetingDate :
                                                                                    (language === 'sinhala' && item.sinhalaMeeetingDate ? item.sinhalaMeeetingDate :
                                                                                        item.englishMeeetingDate)) || 'N/A'
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            <ClockCircleOutlined />
                                                                        </span>
                                                                        <p className='id'>
                                                                            {
                                                                                (language === 'tamil' && item.tamilMeeetingTime ? new Date(`1970-01-01T${item.tamilMeeetingTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) :
                                                                                    (language === 'sinhala' && item.sinhalaMeeetingTime ? new Date(`1970-01-01T${item.sinhalaMeeetingTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) :
                                                                                        new Date(`1970-01-01T${item.englishMeeetingTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }))) || 'N/A'
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <p>
                                                                    {
                                                                        language === 'tamil' ? 'சந்திப்பு தேதி விரைவில் கிடைக்கும்' : language === 'sinhala' ? 'Meeting links ඉක්මනින් ලබා ගත හැකි වනු ඇත' : 'Meeting date will be available soon'
                                                                    }
                                                                </p>
                                                            }
                                                        </div>
                                                        <Divider />
                                                        <div className='meeting-link'>
                                                            <h2>
                                                                {language === 'tamil' ? 'சந்திப்பு இணைப்பு' : language === 'sinhala' ? 'Meeting Link' :
                                                                    'Meeting Link'}
                                                            </h2>
                                                            {(
                                                                language === 'tamil' && item.tamilMeeetingURL ? item.tamilMeeetingURL : (language === 'sinhala' && item.sinhalaMeeetingURL ? item.sinhalaMeeetingURL : item.englishMeeetingURL)
                                                            ) ?
                                                                <div className='meetlink-continer'>
                                                                    <section className='links'>
                                                                        <Button
                                                                            className='join-link'
                                                                            type='link'
                                                                            onClick={() => handleJoinMeeting(item)}
                                                                            style={{
                                                                                padding: '0'
                                                                            }}
                                                                        >
                                                                            {
                                                                                (language === 'tamil' ? 'சந்திப்பு இணையத்தில் சேர' : language === 'sinhala' ? 'Meeting එකට මෙතනින් සම්බන්ධ වන්න' : 'Join Meeting')
                                                                            }
                                                                        </Button>
                                                                        <Button
                                                                            type='link'
                                                                            size='small'
                                                                            className='copy-btn'
                                                                            onClick={() => copyMeetingLink(item)}
                                                                        >
                                                                            {language === 'tamil' ? 'மீட்டிங் இணைப்பை நகலெடுக்கவும்' : language === 'sinhala' ? 'Meeting Link මෙතනින් copy කරන්න' :
                                                                                'Copy Meeting Link'}
                                                                        </Button>
                                                                    </section>
                                                                    <section className='codes'>
                                                                        <div>
                                                                            <span>
                                                                                {language === 'tamil' ? 'சந்திப்பு ஐடி' : language === 'sinhala' ? 'Meeting ID:' :
                                                                                    'Meeting ID:'}
                                                                            </span>
                                                                            <p className='id'>
                                                                                {
                                                                                    (language === 'tamil' && item.tamilMeeetingId ? item.tamilMeeetingId :
                                                                                        (language === 'sinhala' && item.sinhalaMeeetingId ? item.sinhalaMeeetingId :
                                                                                            item.englishMeeetingId)) || 'N/A'
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                {language === 'tamil' ? 'கடவுச்சொல்' : language === 'sinhala' ? 'Meeting එක සඳහා මුරපදය' :
                                                                                    'Passcode:'}
                                                                            </span>
                                                                            <p className='id'>
                                                                                {
                                                                                    (language === 'tamil' && item.tamilMeeetingPassword ? item.tamilMeeetingPassword :
                                                                                        (language === 'sinhala' && item.sinhalaMeeetingPassword ? item.sinhalaMeeetingPassword :
                                                                                            item.englishMeeetingPassword)) || 'N/A'
                                                                                }
                                                                            </p>

                                                                        </div>
                                                                    </section>
                                                                </div>
                                                                :
                                                                <div className='no-meet-links'>
                                                                    <p>
                                                                        {language === 'tamil' ? 'சந்திப்பு இணைப்பு விரைவில் கிடைக்கும்' : language === 'sinhala' ? 'හමුවීම් සබැඳිය ඉක්මනින් ලබා ගත හැකි වනු ඇත' : 'Meeting link will be available soon'}
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        </Spin>
                    </div>
                </div>
                <div className='home-right'>
                    <Spin
                        spinning={loadingNote}
                        tip="Please wait..."
                    >
                        <div className='special-notes'>
                            {userProfile?.role === 'superadmin' &&
                                <div className='note-header'>
                                    {note &&
                                        <div style={{
                                            marginRight: '10px'
                                        }}>
                                            <Popconfirm
                                                title="Are you sure to delete this note?"
                                                onConfirm={handleDeleteNote}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type='link'
                                                    danger
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    }
                                    <Button
                                        onClick={() => setIsModalOpen(true)}
                                        type='primary'
                                        className='add-btn'
                                    >
                                        <PlusOutlined />
                                    </Button>
                                </div>}
                            <h2 className='title'>
                                {language === 'tamil' ? 'சிறப்பு குறிப்புகள்' : language === 'sinhala' ? 'විශේෂ සටහන්' : 'Special Notes'}
                            </h2>

                            {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') &&
                                <Tabs
                                    defaultActiveKey={selectedNoteTab}
                                    centered
                                    onChange={(key) => setSelectedNoteTab(key)}
                                    items={[
                                        {
                                            key: 'teachers',
                                            label: 'For Teachers',
                                            children: <ReactQuill className='quill' readOnly theme="bubble" value={note ? (language === 'tamil' ? note.find(note => note.visibleFor === 'teachers')?.noteTamil : language === 'sinhala' ? note.find(note => note.visibleFor === 'teachers')?.noteSinhala : note.find(note => note.visibleFor === 'teachers')?.noteEnglish) : ''} />
                                        },
                                        {
                                            key: 'students',
                                            label: 'For Students',
                                            children: <ReactQuill className='quill' readOnly theme="bubble" value={note ? (language === 'tamil' ? note.find(note => note.visibleFor === 'students')?.noteTamil : language === 'sinhala' ? note.find(note => note.visibleFor === 'students')?.noteSinhala : note.find(note => note.visibleFor === 'students')?.noteEnglish) : ''} />
                                        }
                                    ]}
                                />
                            }

                            {userProfile?.role === 'student' &&
                                <ReactQuill className='quill' readOnly theme="bubble" value={note ? (language === 'tamil' ? note.find(note => note.visibleFor === 'students')?.noteTamil : language === 'sinhala' ? note.find(note => note.visibleFor === 'students')?.noteSinhala : note.find(note => note.visibleFor === 'students')?.noteEnglish) : ''} />}

                            {
                                userProfile?.role === 'teacher' &&
                                <ReactQuill className='quill' readOnly theme="bubble" value={note ? (language === 'tamil' ? note.find(note => note.visibleFor === 'teachers')?.noteTamil : language === 'sinhala' ? note.find(note => note.visibleFor === 'teachers')?.noteSinhala : note.find(note => note.visibleFor === 'teachers')?.noteEnglish) : ''} />
                            }
                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title="Add new note"
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <div className='drawer-header'>
                    <p>
                        Please select the language for the note.
                    </p>
                    <Checkbox
                        defaultChecked
                        disabled
                    >
                        English
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                    >
                        Sinhala
                    </Checkbox>
                </div>
                <Spin spinning={loadingNote} tip="Please wait...">
                    <Form
                        layout="vertical"
                        onFinish={onFinishNote}
                    >
                        <Form.Item
                            name="noteEnglish"
                            label="Note English"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter content description",
                                },
                            ]}
                        >
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The content starts here..."
                                style={{
                                    height: '100%'
                                }}
                            />
                        </Form.Item>

                        {isSinhalaEnabled &&
                            <>
                                <Divider>Sinhala</Divider>
                                <Form.Item
                                    name="noteSinhala"
                                    label="Note Sinhala"
                                >
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The content starts here..."
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </>
                        }

                        <Form.Item
                            name="visibleFor"
                            label="This course is visible for"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course's visibility",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course's visibility"
                            >
                                <Option value="students">Students</Option>
                                <Option value="teachers">Teachers</Option>
                            </Select>
                        </Form.Item>


                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit">
                                    Add Note
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            <AddSession
                isOpen={isAddSessionOpen}
                setOpen={setIsAddSessionOpen}
                cources={cources}
                handleGetSessions={handleGetSessions}
            />

            <EditSession
                isOpen={isEditSessionOpen}
                setOpen={setIsEditSessionOpen}
                selectedSession={selectedSession}
                cources={cources}
                handleGetSessions={handleGetSessions}
            />
        </div>
    )
}

export default Home