import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux';

import { Button, Popconfirm, Spin, Tabs, Tag, notification } from 'antd';

import { deleteCenter, getAdminsByCenter, getCenterById } from '../../../../Constants/Api/functions/function';

import EditSchool from './EditSchool';

import { DISTRICTS } from '../../../../Constants/Districts/districts';

import './style.scss';
import UsersTable from './UsersTable';
import GroupsTable from './GroupsTable';
import Group from './GroupsTable/Group';

import school from '../../../../Assets/Images/school.png'

const School = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [centerId, setCenterId] = useState('')
    const [center, setCenter] = useState()
    const [admin, setAdmin] = useState()
    const [users, setUsers] = useState([])
    const allCenters = useSelector(state => state.allCenters.allCenters);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const url = window.location.href;
    const [api, contextHolder] = notification.useNotification();
    const language = localStorage.getItem('language');

    useEffect(() => {
        setCenterId(url.split('/').pop())
    }, [url])

    useEffect(() => {
        if (centerId) {
            if (allCenters.length > 0) {
                const center = allCenters.find(center => center.id === centerId);
                setCenter(center);
                handleGetAdmin(centerId);
            } else {
                hadleGetCenter();
            }
        }
    }, [allCenters, centerId])

    const hadleGetCenter = async () => {
        const res = await getCenterById(centerId);
        setCenter(res[0]);
        await handleGetAdmin(centerId);
    }

    const renderDistrict = (district) => {
        const districtName = DISTRICTS.find(dis => dis.val === district);
        return districtName?.name;
    }

    const handleGetAdmin = async (centerId) => {
        const res = await getAdminsByCenter(centerId);
        setAdmin(res);
    }

    const handleDelete = async () => {
        try {
            await deleteCenter(centerId);
            api.success({
                message: 'Success',
                description: 'Center deleted successfully'
            });
            setTimeout(() => {
                window.location.href = '/dashboard/schools';
            }, 1000);
        } catch (error) {
            console.log(error);
            deleteErrorNotification();
        }
    }

    const confirm = async (e) => {
        await handleDelete();
    };

    const deleteErrorNotification = () => {
        api.error({
            message: 'Error',
            description: 'Error deleting center'
        });
    }

    const items = [
        {
            key: '1',
            label:
                <>
                    {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') ? 'Users' : (language === 'sinhala' ? 'සගයන්' : language === 'tamil' ? 'சக' : 'Colleagues')}
                </>,
            children: <UsersTable centerId={centerId} setUsersParent={setUsers} />
        },
        {
            key: '2',
            label: language === 'sinhala' ? 'කණ්ඩායම්' : language === 'tamil' ? 'குழுக்கள்' : 'Groups',
            children: <>
                {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin')
                    ? <GroupsTable centerId={centerId} users={users} />
                    : <Group
                        centerId={centerId} groupId={userProfile?.groupId} />
                }
            </>
        },
    ];

    return (
        <div className='school'>
            {contextHolder}
            {center ?
                <div className='container'>
                    <section className='left'>
                        <div className='school-image'>
                            <img src={school} alt='school' />
                        </div>
                        <div className='school-name'>
                            <h1>{center?.name}</h1>
                        </div>
                        <div className='school-info'>
                            <div className='school-info-item'>
                                <h2>{language === 'sinhala' ? 'ලිපිනය' : language === 'tamil' ? 'முகவரி' : 'Address'}</h2>
                                <p>
                                    {center?.address || 'N/A'}
                                </p>
                            </div>
                            <div className='school-info-item'>
                                <h2>{language === 'sinhala' ? 'දුරකථන' : language === 'tamil' ? 'தொலைபேசி' : 'Phone'}</h2>
                                <p>
                                    {center?.contactNumber || 'N/A'}
                                </p>
                            </div>
                            <div className='school-info-item'>
                                <h2>{language === 'sinhala' ? 'ඊමේල්' : language === 'tamil' ? 'மின்னஞ்சல்' : 'Email'}</h2>
                                <p>
                                    {center?.email || 'N/A'}
                                </p>
                            </div>
                            <div className='school-info-item'>
                                <h2>{language === 'sinhala' ? 'දිස්ත්රික්කය' : language === 'tamil' ? 'மாவட்டம்' : 'District'}</h2>
                                <p>
                                    {center?.district ? renderDistrict(center?.district) : 'N/A'}
                                </p>
                            </div>
                            {/* <div className='school-info-item'>
                                <h2>
                                    Map Location
                                </h2>
                                <p>
                                    {center?.mapLocation
                                        ?
                                        <a href={center?.mapLocation} target='_blank' rel='noreferrer'>View Map</a>
                                        :
                                        'N/A'
                                    }
                                </p>
                            </div> */}
                            <div >
                                <h3>{language === 'sinhala' ? 'පරිපාලක' : language === 'tamil' ? 'நிர்வாகி' : 'Administrators'}</h3>
                                <p>
                                    {admin?.length > 0
                                        ?
                                        admin?.map((ad, index) => {
                                            return (
                                                <Tag
                                                    color='blue'
                                                    style={{
                                                        fontSize: '0.9rem',
                                                        margin: '0.5rem 0.5rem 0.5rem 0',
                                                        padding: '0.25rem'
                                                    }}
                                                    key={index}>{ad?.name}</Tag>
                                            )
                                        })
                                        :
                                        'N/A'
                                    }
                                </p>
                            </div>
                        </div>
                        {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') &&
                            <div className='school-actions'>
                                <Button
                                    onClick={() => setIsOpen(true)}
                                    type='primary'>Edit</Button>
                                {userProfile?.role === 'superadmin' &&
                                    <Popconfirm
                                        title="Delete this center?"
                                        description="Are you sure you want to delete this center? This action cannot be undone."
                                        onConfirm={confirm}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type='primary'
                                            danger>Delete</Button>
                                    </Popconfirm>}
                            </div>
                        }
                    </section>

                    <section className='right'>
                        <div className='tabs-container'>
                            <Tabs
                                style={{ width: '100%' }}
                                defaultActiveKey="1" items={items} />
                        </div>
                    </section>
                </div>
                :
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh'
                    }}
                >
                    <Spin
                        size='large'
                    />
                </div>
            }
            <EditSchool
                isOpen={isOpen}
                setOpen={setIsOpen}
                center={center}
                handleGetcenter={hadleGetCenter}
            />
        </div>
    )
}

export default School