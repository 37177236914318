import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Antd
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';

import { useHistory, Link, useParams } from 'react-router-dom';

import { getAllCenters, getAllUsers, getAttendanceBySession, getSessionById } from '../../../../Constants/Api/functions/function';

import { setAllUsers } from '../../../../Reducers/allUsersReducer';
import dayjs from 'dayjs';
import { setAllCenters } from '../../../../Reducers/allCentersReducer';

const SessionDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [session, setSession] = useState();
    const sessionId = useParams().id;

    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');
    const language = localStorage.getItem('language');

    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allUsers = useSelector((state) => state.allUsers.allUsers);
    const allSessons = useSelector(state => state.allSessons.allSessons);
    const allCenters = useSelector(state => state.allCenters.allCenters);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (allUsers.length === 0) {
            handleGetAllUsers();
        }

        if (allCenters.length === 0) {
            handleGetAllCenters();
        }

    }, [allUsers, allCenters])

    useEffect(() => {
        if (userProfile?.role === 'student' || userProfile?.role === 'teacher') {
            history.push(`/dashboard/home`)
        }
    }, [userProfile])

    useEffect(() => {
        if (sessionId && allUsers.length > 0) {
            handleGetSessionData();
        }
    }, [sessionId, allUsers])

    useEffect(() => {
        if (allSessons) {
            let session = allSessons.find((session) => session.id === sessionId);
            setSession(session);
        } else {
            getSessionData();
        }
    }, [allSessons])

    useEffect(() => {
        if (sessionData) {
            let data = sessionData.map((user, index) => {
                return {
                    key: index,
                    name: user.name,
                    attendance: user.attendance?.isPresent || false,
                    attendanceTime: user.attendance?.createdAt ? dayjs(user.attendance?.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
                    role: user.role,
                    userId: user.id
                }
            });
            setTableData(data);
        }
    }, [sessionData])

    const handleGetAllCenters = async () => {
        setIsLoading(true)
        try {
            const response = await getAllCenters();
            if (response) {
                dispatch(setAllCenters(response))
            }
        } catch (error) {
            console.error('getAllCenters', error);
        }
        setIsLoading(false)
    }

    const handleExportToExcel = () => {
        if (sessionData.length > 0) {
            const sheetData = sessionData.map((user) => {
                return {
                    'Name': user.name,
                    'Role': user.role,
                    'Attendance': user.attendance?.isPresent ? 'Present' : 'Absent',
                    'Attendance Time': user.attendance?.createdAt ? dayjs(user.attendance?.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
                    'School': user?.centerId ? findUserCenter(user?.centerId) : 'N/A'
                }
            });

            import("xlsx").then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(sheetData);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'sessionData');
            });
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], { type: EXCEL_TYPE });
            FileSaver.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const findUserCenter = (centerId) => {
        const center = allCenters?.find(center => center?.id === centerId)
        return center?.name
    }

    const getSessionData = async () => {
        try {
            const response = await getSessionById(sessionId);
            if (response) {
                setSession(response);
            }
        } catch (error) {
            console.error('getSessionData', error);
        }
    }

    const handleGetSessionData = async () => {
        setIsLoading(true);
        try {
            const response = await getAttendanceBySession(sessionId);
            let data = allUsers
                .filter(user => user.role === 'student' || user.role === 'teacher')
                .map((user) => {
                    let attendance = response.find((attendance) => attendance.userId === user.id);
                    return {
                        ...user,
                        attendance: attendance || null
                    }
                });
            setSessionData(data);
        } catch (error) {
            console.error('handleGetSessionData', error);
        }
        setIsLoading(false);
    }

    const handleGetAllUsers = async () => {
        setIsLoading(true);
        try {
            const response = await getAllUsers();
            if (response) {
                dispatch(setAllUsers(response))
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setIsLoading(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/users/${record.userId}/overview`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/users/${record.userId}/overview`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '20%',
            filters: [
                {
                    text: 'Student',
                    value: 'student',
                },
                {
                    text: 'Teacher',
                    value: 'teacher',
                },
            ],
            onFilter: (value, record) => record.role.indexOf(value) === 0,
            render: (role) => {
                return (
                    <Tag color={role === 'student' ? 'blue' : 'green'}
                        key={role}>
                        {role === 'student' ? 'Student' : 'Teacher'}
                    </Tag>
                )
            }
        },
        {
            title: 'Attendance',
            dataIndex: 'attendance',
            key: 'attendance',
            width: '20%',
            filters: [
                {
                    text: 'Present',
                    value: true,
                },
                {
                    text: 'Absent',
                    value: false,
                },
            ],
            onFilter: (value, record) => record.attendance === value,
            render: (attendance) => {
                return (
                    <Tag color={attendance ? 'green' : 'red'}
                        key={attendance}>
                        {attendance ? 'Present' : 'Absent'}
                    </Tag>
                )
            }
        },
        {
            title: 'Adtendance Time',
            dataIndex: 'attendanceTime',
            key: 'attendanceTime',
            width: '20%',
            sorter: (a, b) => new Date(a.attendance?.time) - new Date(b.attendance?.time),
        }
    ];

    return (
        <div>
            <div
                className='header'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h1>
                    {language === 'tamil' && session?.nameTamil ? session?.nameTamil : (language === 'sinhala' && session?.nameSinhala ? session?.nameSinhala : session?.nameEnglish)}
                </h1>
                <h2
                    style={{
                        color: 'gray',
                        fontSize: 20,
                    }}
                >{`Date: `}
                    {(language === 'tamil' && session?.tamilMeeetingDate ? session?.tamilMeeetingDate :
                        (language === 'sinhala' && session?.sinhalaMeeetingDate ? session?.sinhalaMeeetingDate :
                            session?.englishMeeetingDate)) || 'N/A'}
                </h2>

                {userProfile?.role === 'superadmin' &&
                    <Button
                        type='primary'
                        onClick={handleExportToExcel}
                    >
                        Export to Excel
                    </Button>}
            </div>
            <div className='table'>
                <Spin spinning={isLoading}>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={{
                            pageSize: 20,
                            showSizeChanger: false,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                        }}
                    />
                </Spin>
            </div>
        </div>
    )
}

export default SessionDetails