import React, { useEffect, useRef, useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin } from 'antd';

// API
import { editCenter } from '../../../../../Constants/Api/functions/function';

import { DISTRICTS } from '../../../../../Constants/Districts/districts';
const { Option } = Select;

const EditSchool = ({ setOpen, isOpen, center, handleGetcenter }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef(null);

    useEffect(() => {
        if (center) {
            setFormValues()
        }
    }, [center, formRef, isOpen])

    const setFormValues = () => {
        formRef?.current?.setFieldsValue({
            name: center.name,
            district: center.district,
            address: center.address,
            contactNumber: center.contactNumber,
            email: center.email,
            mapLocation: center.mapLocation
        });
    }

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);
        values.id = center.id;

        setIsLoading(true)
        try {
            const response = await editCenter(values);
            if (response.status === 200) {
                openNotificationWithIcon('success')
                setIsLoading(false)
                setOpen(false)
                handleGetcenter()
            } else {
                openNotificationWithIcon('error', response.data)
                setIsLoading(false)
            }
        } catch (error) {
            openNotificationWithIcon('error', error)
            setIsLoading(false)
        }
    }


    const closeAddSchoolDrawer = () => {
        setOpen(false)
    }

    const openNotificationWithIcon = (type, error) => {
        api[type]({
            message: type === "success"
                ? "Center updated successfully"
                : "Error updating center",
            description: type === "success"
                ? "Center has been updated successfully."
                :
                `There was an error updating center. Please try again later. ${error}`
        });
    };

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit School"
                width={720}
                onClose={closeAddSchoolDrawer}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="School Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter school's name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter school's name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="district"
                                    label="District"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select user district',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select user district"
                                    >
                                        {
                                            DISTRICTS.map((district) => (
                                                <Option value={district.val}>{district.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="School Address"
                                >
                                    <Input placeholder="Please enter school's address" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="contactNumber"
                                    label="Contact Number"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^0[0-9]{9}$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="0XX XXXX XXX" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The email is not a valid!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Please enter user email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="mapLocation"
                                    label="Map Location"
                                >
                                    <Input placeholder="Please enter school map location" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditSchool