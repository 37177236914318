import {
    HomeOutlined,
    TeamOutlined,
    BookOutlined,
    BankOutlined,
    UsergroupAddOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";

const SUPER_ADMIN_MENU_ITEMS = [
    { key: "1", icon: <HomeOutlined />, label: "Home", labelSin: "මුල් පිටුව", href: "/dashboard/home" },
    { key: "2", icon: <TeamOutlined />, label: "Users", labelSin: "පරිශීලකයන්", href: "/dashboard/users" },
    { key: "3", icon: <BookOutlined />, label: "Courses", labelSin: "පාඨමාලා", href: "/dashboard/cources" },
    { key: "4", icon: <BankOutlined />, label: "Schools", labelSin: "පාසල්", href: "/dashboard/schools" },
    { key: "5", icon: <FileDoneOutlined />, label: "Assessments", labelSin: "ඇගයීම්", href: "/dashboard/assessments" },
    { key: "6", icon: <UsergroupAddOutlined />, label: "Attendance", labelSin: "පැමිණීම", href: "/dashboard/attendance" }
]

const ADMIN_MENU_ITEMS = [
    { key: "1", icon: <HomeOutlined />, label: "Home", labelSin: "මුල් පිටුව", href: "/dashboard/home" },
    { key: "2", icon: <TeamOutlined />, label: "Users", labelSin: "පරිශීලකයන්", href: "/dashboard/users" },
    { key: "3", icon: <BookOutlined />, label: "Courses", labelSin: "පාඨමාලා", href: "/dashboard/cources" },
    { key: "4", icon: <BankOutlined />, label: "Schools", labelSin: "පාසල්", href: "/dashboard/schools" },
    { key: "5", icon: <FileDoneOutlined />, label: "Assessments", labelSin: "ඇගයීම්", href: "/dashboard/assessments" },
]

const STUDENT_MENU_ITEMS = [
    { key: "1", icon: <HomeOutlined />, label: "Home", labelSin: "මුල් පිටුව", href: "/dashboard/home" },
    { key: "3", icon: <BookOutlined />, label: "Courses", labelSin: "පාඨමාලා", href: "/dashboard/cources" },
    { key: "4", icon: <BankOutlined />, label: "School", labelSin: "පාසල", href: "/dashboard/schools" },
    { key: "5", icon: <FileDoneOutlined />, label: "Assessments", labelSin: "ඇගයීම්", href: "/dashboard/assessments" },
]

export {
    SUPER_ADMIN_MENU_ITEMS,
    ADMIN_MENU_ITEMS,
    STUDENT_MENU_ITEMS,
}
