import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

// Antd
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';

// API
import { getUsersByCenter } from '../../../../../Constants/Api/functions/function';

// Styles
import './style.scss';
import { useSelector } from 'react-redux';

const UsersTable = ({ centerId, setUsersParent }) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef(null);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        handleGetAllUsers();
    }, [centerId])

    useEffect(() => {
        if (users?.length) {
            const data = users?.map((user, index) => {
                return {
                    key: index + 1,
                    id: user?.id,
                    name: user?.name,
                    role: user?.role,
                    email: user?.email,
                }
            })
            setTableData(data)
        }
    }, [users])


    const handleGetAllUsers = async () => {
        setIsLoading(true)
        try {
            const response = await getUsersByCenter(centerId);
            if (response) {
                setUsers(response)
                setUsersParent(response)
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setIsLoading(false)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/users/${record.id}/overview`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/users/${record.id}/overview`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '30%',
            filters: [
                {
                    text: 'Admin',
                    value: 'admin',
                },
                {
                    text: 'Student',
                    value: 'student',
                },
                {
                    text: 'Teacher',
                    value: 'teacher',
                },
            ],
            onFilter: (value, record) => record.role.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === 'admin') {
                    color = 'green';
                }
                if (text === 'student') {
                    color = 'blue';
                }
                if (text === 'teacher') {
                    color = 'purple';
                }
                return (
                    <Tag color={color} key={text}>
                        {text?.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            ...getColumnSearchProps('email'),
        },
    ];

    const stuedntsColumns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '30%',
            filters: [
                {
                    text: 'Admin',
                    value: 'admin',
                },
                {
                    text: 'Student',
                    value: 'student',
                },
                {
                    text: 'Teacher',
                    value: 'teacher',
                },
            ],
            onFilter: (value, record) => record.role.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === 'admin') {
                    color = 'green';
                }
                if (text === 'student') {
                    color = 'blue';
                }
                if (text === 'teacher') {
                    color = 'purple';
                }
                return (
                    <Tag color={color} key={text}>
                        {text?.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            ...getColumnSearchProps('email'),
        },
    ];

    return <div className='users' style={{
        width: '100%',
    }}>
        <div className='users-action-buttons'>
            <h2>
                Users List <Tag
                    style={{
                        transform: 'translateY(-3px)',
                        marginLeft: '10px'
                    }}
                    color="blue">{tableData?.length}</Tag>
            </h2>
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Users..."
        >
            <Table
                style={{
                    width: '100%',
                }}
                columns={(userProfile?.role === "student" || userProfile?.role === 'teacher') ? stuedntsColumns : columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 10,
                    position: ["bottomRight"],
                }}
                scroll={window.innerWidth <= 768 && { x: 1000 }}
            />
        </Spin>
    </div>;
};
export default UsersTable;