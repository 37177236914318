import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { deleteUser, getAllCenters, getGroupsByCenterId, getUserById } from '../../../../../Constants/Api/functions/function';

import { Avatar, Button, Spin, Popconfirm, notification, Tag, Divider } from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons'

import EditOverview from './EditOverview'

import './style.scss'

import male from '../../../../../Assets/Images/male.png'
import female from '../../../../../Assets/Images/female.png'

const UserOverview = ({ selectedUser, centers }) => {
    const url = window.location.pathname;
    const [Loading, setLoading] = useState(true)
    const [centerName, setCenterName] = useState('')
    const [user, setUser] = useState(null)
    const [editOverviewOpen, setEditOverviewOpen] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [userId, setUserId] = useState()
    const [groupName, setGroupName] = useState('')
    const userProfile = useSelector(state => state.userProfile.userProfile)
    const [allCenters, setAllCenters] = useState()
    const [adminsCenters, setAdminsCenters] = useState()

    useEffect(() => {
        if (url.includes('users')) {
            const userId = url.split('/')[3];
            setUserId(userId)
        } else {
            setUserId(userProfile.id)
        }
    }, [url, userProfile])


    useEffect(() => {
        if (url.includes('users')) {
            if (selectedUser) {
                setLoading(false)
                setUser(selectedUser)
            } else {
                if (userId) {
                    handleGetUser()
                }
            }
        } else {
            setLoading(false)
            setUser(userProfile)
        }
    }, [selectedUser, url, userProfile, userId])

    useEffect(() => {
        if (user?.groupId) {
            renderGroupName(user.groupId)
        } else {
            setGroupName('N/A')
        }

        if (user?.role === 'admin' && user?.adminsCenterIds) {
            const centerIds = user?.adminsCenterIds.split(',')

            // get center names
            const centerNames = []
            centerIds.forEach((centerId) => {
                const center = allCenters.find((center) => center.id === centerId)
                centerNames.push(center?.name)
            })
            setAdminsCenters(centerNames)
        }

    }, [user, allCenters])

    useEffect(() => {
        setAllCenters(centers)

        if (user?.centerId) {
            if (centers?.length > 0) {
                const center = centers.find((center) => center.id === user.centerId)
                setCenterName(center?.name)
            } else {
                rendeCenterName().then(setCenterName);
            }
        } else {
            setCenterName('N/A')
        }
    }, [centers, user])

    const rendeCenterName = async () => {
        try {
            const response = await getAllCenters()
            setAllCenters(response)

            if (response) {
                const center = response.find((center) => center.id === user.centerId)
                return center.name
            }
        } catch (error) {
            console.error('getGroupsByCenterId', error)
        }
    }

    const renderGroupName = async (groupId) => {
        try {
            const response = await getGroupsByCenterId(user.centerId)
            if (response) {
                const group = response.find((group) => group.id === groupId)
                setGroupName(group.name)
            }
        } catch (error) {
            console.error('getGroupsByCenterId', error)
        }
    }

    const handleGetUser = async () => {
        setLoading(true)
        try {
            const response = await getUserById(userId)
            if (response) {
                setUser(response)
            }
        } catch (error) {
            console.error('getUser', error)
        }
        setLoading(false)
    }

    const handleUserDelete = async () => {
        try {
            const response = await deleteUser(userId)
            if (response && response.status === 200) {
                deleteUserNotification('success')
                window.location = '/dashboard/users'
            } else {
                deleteUserNotification('error', 'Something went wrong')
            }
        } catch (error) {
            deleteUserNotification('error', error)
        }
    }

    const deleteUserNotification = (type, description) => {
        api[type]({
            message: type === 'success' ? 'Success' : 'Something went wrong',
            description: type === 'success'
                ? 'User deleted successfully'
                : `Error: ${description}`,
        });
    };

    const getRoleTag = () => {
        const role = user?.role;
        let color = 'geekblue';
        if (role === 'superadmin') {
            color = 'volcano';
        }
        if (role === 'admin') {
            color = 'green';
        }
        if (role === 'student') {
            color = 'blue';
        }
        if (role === 'teacher') {
            color = 'purple';
        }
        return (
            <Tag color={color} key={role}>
                {role?.toUpperCase()}
            </Tag>
        );
    };

    return (
        <Spin
            spinning={Loading}
            tip='Loading...'
        >
            <div className='user-overview'>
                {contextHolder}
                <h1>
                    Basic info
                    {userProfile?.role === 'superadmin' &&
                        <Popconfirm
                            title="Delete user?"
                            description="Are you sure you want to delete this user?"
                            onConfirm={handleUserDelete}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                style={{
                                    transform: 'translateY(-1px)',
                                    marginLeft: '15px',
                                }}
                                size='small'
                                danger>
                                Delete <DeleteOutlined />
                            </Button>
                        </Popconfirm>}
                    <Button
                        type='link'
                        style={{
                            transform: 'translateY(-1px)',
                            padding: 0,
                            marginLeft: '15px',
                        }}
                        onClick={() => setEditOverviewOpen(true)}
                    >
                        Edit <EditOutlined />
                    </Button>
                </h1>

                <div className='user-overview__basic-info'>
                    <div className='user-overview__basic-info__image'>
                        <div className='left'>
                            <Avatar
                                src={user?.gender === 'male' ? male : female} size={100}
                            />
                        </div>
                    </div>

                    <div className='details'>
                        <section className='left'>
                            <div>
                                <h4>User Name</h4>
                                <p>
                                    {user?.name}
                                </p>
                            </div>

                            <div>
                                <h4>User ID</h4>
                                <p>
                                    {user?.id}
                                </p>
                            </div>

                            <div>
                                <h4>Email</h4>
                                <p>
                                    {user?.email}
                                </p>
                            </div>

                            <div>
                                <h4>Created Date Time</h4>
                                <p>
                                    {
                                        user?.createdAt
                                    }
                                </p>
                            </div>

                            <div>
                                <h4>Role</h4>
                                <p>
                                    {getRoleTag()}
                                </p>
                            </div>

                            <div>
                                <h4>Contact Number</h4>
                                <p>
                                    {user?.contactNumber || 'N/A'}
                                </p>
                            </div>
                        </section>

                        <section className='right'>
                            {user?.role !== 'admin' &&
                                <div>
                                    <h4>Center</h4>
                                    <p>
                                        {centerName}
                                    </p>
                                </div>}

                            <div>
                                <h4>Address</h4>
                                <p>
                                    {user?.address || 'N/A'}
                                </p>
                            </div>

                            <div>
                                <h4>Date of birth</h4>
                                <p>
                                    {user?.dateOfBirth || 'N/A'}
                                </p>
                            </div>

                            <div>
                                <h4>Gender</h4>
                                <p>
                                    {user?.gender === 'male' ? 'Male' : "Female"}
                                </p>
                            </div>

                            <div>
                                <h4>
                                    Group Name
                                </h4>
                                <p>
                                    {groupName}
                                </p>
                            </div>
                        </section>
                    </div>

                    <Divider />

                    {user?.role === 'admin' &&
                        <div>
                            <h4>
                                Coordinating Centers
                            </h4>

                            {
                                adminsCenters?.length > 0
                                    ? adminsCenters.map((center, index) => (
                                        <Tag key={index} color='blue'>
                                            {center}
                                        </Tag>
                                    ))
                                    : 'N/A'
                            }
                        </div>
                    }
                </div>
                <EditOverview
                    open={editOverviewOpen}
                    setOpen={setEditOverviewOpen}
                    user={user}
                    getUser={handleGetUser}
                    centers={allCenters}
                />
            </div>
        </Spin>
    )
}

export default UserOverview