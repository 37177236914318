import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

// Antd
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';

// API
import { getGroupsByCenterId } from '../../../../../Constants/Api/functions/function';

// Redux
import { useDispatch } from 'react-redux';
import { setCenterGroups } from '../../../../../Reducers/centerGroupsReducer';

// Styles
import './style.scss';
import CreateGroup from './CreateGroup';

const GroupsTable = ({ centerId, users }) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [groups, setGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef(null);
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        handleGetGroups();
    }, [centerId])

    useEffect(() => {
        if (groups?.length) {
            const data = groups?.map((group, index) => {
                return {
                    key: index + 1,
                    id: group?.id,
                    name: group?.name,
                    leader: getLeadreName(group?.leaderId),
                }
            })
            setTableData(data)
        }
    }, [groups])

    const getLeadreName = (id) => {
        const user = users.find(user => user.id === id);
        return user?.name
            ||
            <p
                style={{
                    color: '#bfbfbf',
                }}
            >
                No Leader Assigned
            </p>;
    }

    const handleGetGroups = async () => {
        setIsLoading(true)
        try {
            const response = await getGroupsByCenterId(centerId);
            if (response) {
                setGroups(response)
                dispatch(setCenterGroups(response))
            }
        } catch (error) {
            console.error('getGroupsCenter', error);
        }
        setIsLoading(false)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/schools/${centerId}/group/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/schools/${centerId}/group/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Leader',
            dataIndex: 'leader',
            key: 'leader',
            width: '30%',
        },
    ];

    return <div className='users' style={{
        width: '100%',
    }}>
        <div className='users-action-buttons'>
            <h2>
                Groups List <Tag
                    style={{
                        transform: 'translateY(-3px)',
                        marginLeft: '10px'
                    }}
                    color="blue">{tableData?.length}</Tag>
            </h2>

            <Button
                onClick={() => setIsOpen(true)}
                type='primary'>Create Group</Button>
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading groups..."
        >
            <Table
                style={{
                    width: '100%',
                }}
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 10,
                    position: ["bottomRight"],
                }}
            />
        </Spin>

        <CreateGroup
            isOpen={isOpen}
            setOpen={setIsOpen}
            users={users}
            handleGetGroups={handleGetGroups}
            centerId={centerId}
            groups={groups}
        />

    </div>;
};
export default GroupsTable;