import React, { useState } from 'react'

import { useSelector } from 'react-redux';

// Antd
import { Button, Drawer, Form, Input, Select, notification, Spin, Checkbox } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss';
import { addAssestment } from '../../../../Constants/Api/functions/function';


const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const AddAssessment = ({
    isOpen,
    setOpen,
    handleGetAssessments,
    cources
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const [isTamilEnabled, setIsTamilEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.createdBy = userProfile.id;

        setIsLoading(true);

        try {
            const res = await addAssestment(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New assessment has been added successfully.'
                );
                setOpen(false);
                await handleGetAssessments();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddAssessment', error);
            openNotification('error',
                'Error',
                'Something went when adding new assessment.'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Create a new assessment"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}


            >
                <div className='drawer-header'>
                    <p>
                        Please select Assessment languages
                    </p>
                    <Checkbox
                        defaultChecked
                        disabled
                    >
                        English
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                    >
                        Sinhala
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => setIsTamilEnabled(e.target.checked)}
                    >
                        Tamil
                    </Checkbox>
                </div>
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {cources?.map((cource, index) => (
                                    <Option
                                        key={index}
                                        value={cource.id}
                                    >
                                        {cource.nameEnglish}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Name (English)"
                            name="nameEnglish"
                            rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                            <Input
                                placeholder="Please enter assessment name"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description (English)"
                            name="discriptionEnglish"
                            rules={[{
                                required: true,
                                message: 'Please enter assessment description!'
                            }]}>
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The description of the assessment"
                                style={{
                                    height: '100%',
                                    zIndex: 10000,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Assessment URL"
                            name="assessmentURLEnglish"
                            rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                            <Input
                                placeholder="Please enter assessment URL"
                            />
                        </Form.Item>
                        {isSinhalaEnabled && (
                            <>
                                <Form.Item
                                    label="Name (Sinhala)"
                                    name="nameSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                                    <Input
                                        placeholder="Please enter assessment name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Description (Sinhala)"
                                    name="discriptionSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment description!' }]}>
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The description of the assessment"
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Assessment URL (Sinhala)"
                                    name="assessmentURLSinhala"
                                    rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                                    <Input
                                        placeholder="Please enter assessment URL"
                                    />
                                </Form.Item>
                            </>
                        )}

                        {isTamilEnabled && (
                            <>
                                <Form.Item
                                    label="Name (Tamil)"
                                    name="nameTamil"
                                    rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                                    <Input
                                        placeholder="Please enter assessment name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Description (Tamil)"
                                    name="discriptionTamil"
                                    rules={[{ required: true, message: 'Please enter assessment description!' }]}>
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The description of the assessment"
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Assessment URL (Tamil)"
                                    name="assessmentURLTamil"
                                    rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                                    <Input
                                        placeholder="Please enter assessment URL"
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label="End Date"
                            name="endDate"
                            rules={[{
                                required: true,
                                message: 'Please select end date!'
                            }]}>
                            <Input
                                type="date"
                            />
                        </Form.Item>

                        <Button
                            type="primary" htmlType="submit">
                            Create Assessment
                        </Button>

                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddAssessment