import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setAllAssessments } from '../../../Reducers/allAssessmentsReducer'

import { Link } from 'react-router-dom'

import { getAllCoursesByRole, getAssestmentsByRole } from '../../../Constants/Api/functions/function'

import { Button, Input, Select, Space, Spin, Table } from 'antd'
import {
    PlusOutlined,
    SearchOutlined
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';

// API
import { setAllCources } from '../../../Reducers/allCourcesReducer'

import './style.scss'

import AddAssessment from './AddAssessment'

const Assessments = () => {
    const [loading, setLoading] = useState(false)
    const [openAddAssessment, setOpenAddAssessment] = useState(false)
    const allCources = useSelector(state => state.allCources.allCources);
    const [assessments, setAssessments] = useState();
    const language = localStorage.getItem('language');
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allAssessments = useSelector(state => state.allAssessments.allAssessments);
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    useEffect(() => {
        if (!allCources) {
            hadleGetCources();
        }
        if (allAssessments === null) {
            handleGetAssessments();
        } else {
            setAssessments(allAssessments);
        }
    }, [allCources, allAssessments])

    useEffect(() => {
        if (assessments && allCources.length) {
            setTableData(
                assessments.map((assessment) => {
                    const course = allCources.find((course) => course?.id === assessment.courseId);
                    return {
                        id: assessment?.id,
                        key: assessment?.id,
                        title: assessment?.nameEnglish,
                        course: language === 'tamil' && course?.nameTamil ? course?.nameTamil : (language === 'sinhala' && course?.nameSinhala ? course?.nameSinhala : course?.nameEnglish),
                        endDate: assessment?.endDate,
                        status: assessment?.attempt,
                        marks: assessment?.attempt?.marks,
                    };
                })
            );
        }
    }, [assessments, allCources]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/dashboard/assessments/${record.id}`}>
                        View
                    </Link>
                </Space>
            ),
        },
        {
            title: 'Assesment Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'id',
            width: '20%',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: '15%',
            sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
        },
    ];

    const studentColumns = [
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/dashboard/assessments/${record.id}`}>
                        View
                    </Link>
                </Space>
            ),
        },
        {
            title: 'Assesment Title',
            dataIndex: 'title',
            key: 'title',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'id',
            width: '20%',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: '15%',
            sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
            render: (endDate) => {
                const now = new Date();
                const end = new Date(endDate);
                const diff = end - now;
                const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
                let color = 'black';
                if (diffDays <= 7) {
                    color = 'blue';
                } else if (diffDays < 0) {
                    color = 'red';
                }
                return (
                    <span style={{ color: color }}>
                        {endDate}
                    </span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (status) => {
                let color = status ? 'green' : 'red';
                return (
                    <span style={{ color: color }}>
                        {status ? 'Attempted' : 'Not Attempted'}
                    </span>
                )
            }
        },
        {
            title: 'Marks',
            dataIndex: 'marks',
            key: 'marks',
            width: '10%',
            render: (marks) => {
                let color = marks ? (marks < 50 ? 'red' : 'green') : 'black';
                return (
                    <span style={{ color: color }}>
                        {marks ? marks : 'N/A'}
                    </span>
                )
            }
        }
    ]

    const hadleGetCources = async () => {
        setLoading(true);
        try {
            const response = await getAllCoursesByRole('superadmin');
            console.log('response', response)
            dispatch(setAllCources(response));
        } catch (error) {
            console.error('hadleGetCources', error);
        }
        setLoading(false);
    }

    const handleGetAssessments = async () => {
        setLoading(true);
        try {
            const response = await getAssestmentsByRole(userProfile.role, userProfile.id);
            if (response.length === 0) {
                dispatch(setAllAssessments(null));
                setAssessments(response);
            } else {
                dispatch(setAllAssessments(response));
                setAssessments(response);
            }
        } catch (error) {
            console.error('handleGetAssessments', error);
        }

        setLoading(false);
    }

    const onFilter = (value) => {
        if (value === 'all') {
            setAssessments(allAssessments);
        } else {
            const filtered = allAssessments.filter(assessment => assessment.courseId === value);
            setAssessments(filtered);
        }
    }

    return (
        <div className='assessments'>
            <div className='assessments-header'>
                <div className='assessments-header-title'>
                    <h1>
                        {language === 'tamil' ? 'மதிப்பீடுகள்' : (language === 'sinhala' ? 'ඇගයීම්' : 'Assessments')}
                    </h1>
                    <span>
                        Filter by course:
                        <Select
                            className='select'
                            defaultValue='all'
                            onChange={onFilter}
                        >
                            <Select.Option value='all'>All</Select.Option>
                            {allCources?.map(course => (
                                <Select.Option key={course.id} value={course.id}>
                                    {language === 'tamil' && course.nameTamil ? course.nameTamil : (language === 'sinhala' && course.nameSinhala ? course.nameSinhala : course.nameEnglish)}
                                </Select.Option>
                            ))}
                        </Select>
                    </span>
                </div>
                {userProfile?.role === 'superadmin' && <div className='actions'>
                    <Button
                        type='primary'
                        className='btn'
                        onClick={() => setOpenAddAssessment(true)}
                    >
                        <PlusOutlined />
                        Create Assessment
                    </Button>
                </div>}
            </div>
            <div className='assessments-list'>
                <Spin spinning={loading}
                    tip='Loading...'
                >
                    <Table
                        columns={(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') ? columns : studentColumns}
                        dataSource={tableData}
                        pagination={{
                            pageSize: 50,
                            position: ["bottomRight"],
                        }}
                        scroll={window.innerWidth <= 768 && { x: 1000 }}
                    />
                </Spin>
            </div>

            <AddAssessment
                isOpen={openAddAssessment}
                setOpen={setOpenAddAssessment}
                cources={allCources}
                handleGetAssessments={handleGetAssessments}
                loading={loading}
            />
        </div>
    )
}

export default Assessments

// hello