import React, { useEffect, useState } from 'react'

// Antd
import { Layout, Menu } from 'antd';

import { Link } from 'react-router-dom';

import {
    ADMIN_MENU_ITEMS,
    SUPER_ADMIN_MENU_ITEMS,
    STUDENT_MENU_ITEMS
} from '../../../../Constants/Menu/menus';

import {
    SUPER_ADMIN,
    ADMIN,
    STUDENT,
    TEACHER,
} from '../../../../Constants/Roles/roles';

import history from '../../../../../history';

import './style.scss'

const { Sider } = Layout;

const SideBar = ({ userData, collapsed, setCollapsed }) => {
    const url = window.location.href;
    const language = localStorage.getItem('language');

    useEffect(() => {
        if (
            url === 'http://localhost:3000/dashboard' ||
            url === 'http://localhost:3000/dashboard/' ||
            url === 'https://stemupspace.azurewebsites.net/dashboard' ||
            url === 'https://spaces.stem.lk/dashboard'
        ) {
            history.push('/dashboard/home');
            window.location.reload();
        }
    }, [url])

    const handleNavClick = () => {
        if (window.innerWidth < 768) {
            setCollapsed(true);
        }
    }

    return (
        <Sider
            breakpoint="md"
            collapsedWidth="0"
            onCollapse={(collapsed) => setCollapsed(collapsed)}
            collapsed={collapsed}
            collapsible
        >
            {/* <div className='shade shade1'></div>
            <div className='shade shade2'></div> */}

            <div className="header-logo-vertical">
                <img
                    src={require('../../../../Assets/Logos/logo.png')}
                    alt="logo"
                />
            </div>
            <Menu
                style={{
                    background: 'transparent',
                }}
                theme="dark"
                mode="inline"
                defaultSelectedKeys={
                    url.includes('home') ? ['1'] :
                        url.includes('users') || url.includes('users') ? ['2'] :
                            url.includes('cources') ? ['3'] :
                                url.includes('schools') ? ['4'] :
                                    url.includes('assessments') ? ['5'] :
                                        url.includes('attendance') ? ['6'] :
                                            []
                }
            >
                {
                    userData.role === SUPER_ADMIN ? SUPER_ADMIN_MENU_ITEMS.map((item) => {
                        return (
                            <Menu.Item
                                key={item.key}
                                icon={item.icon}
                                isSelected={item.href === url}
                                onClick={handleNavClick}
                            >
                                <Link to={item.href}>
                                    {language === 'sinhala' ? item.labelSin : item.label}
                                </Link>
                            </Menu.Item>
                        )
                    }) : userData.role === ADMIN ? ADMIN_MENU_ITEMS.map((item) => {
                        return (
                            <Menu.Item
                                key={item.key}
                                icon={item.icon}
                                isSelected={item.href === url}
                                onClick={handleNavClick}
                            >
                                <Link to={item.href}>
                                    {language === 'sinhala' ? item.labelSin : item.label}
                                </Link>
                            </Menu.Item>
                        )
                    }) : userData.role === STUDENT ? STUDENT_MENU_ITEMS.map((item) => {
                        return (
                            <Menu.Item
                                key={item.key}
                                icon={item.icon}
                                isSelected={item.href === url}
                                onClick={handleNavClick}
                            >
                                <Link to={item.href}>
                                    {language === 'sinhala' ? item.labelSin : item.label}
                                </Link>
                            </Menu.Item>
                        )
                    }) : userData.role === TEACHER ? STUDENT_MENU_ITEMS.map((item) => {
                        return (
                            <Menu.Item
                                key={item.key}
                                icon={item.icon}
                                isSelected={item.href === url}
                                onClick={handleNavClick}
                            >
                                <Link to={item.href}>
                                    {language === 'sinhala' ? item.labelSin : item.label}
                                </Link>
                            </Menu.Item>
                        )
                    }) : <></>
                }
            </Menu>
        </Sider>
    )
}

export default SideBar