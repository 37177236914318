import React, { useEffect, useRef, useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin, Checkbox, Divider } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import { editCourseContent } from '../../../../../../Constants/Api/functions/function';

const { Option } = Select;

const EditContent = ({
    isOpen,
    setOpen,
    hadleGetCourceContents,
    contentData
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const formRef = useRef();

    useEffect(() => {
        if (contentData && formRef.current) {
            formRef.current.setFieldsValue({
                titleEnglish: contentData.titleEnglish,
                titleSinhala: contentData.titleSinhala,
                titleTamil: contentData.titleTamil,
                shortDiscriptionEnglish: contentData.shortDiscriptionEnglish,
                shortDiscriptionSinhala: contentData.shortDiscriptionSinhala,
                shortDiscriptionTamil: contentData.shortDiscriptionTamil,
                videoURLSinhala: contentData.videoURLSinhala,
                videoURLEnglish: contentData.videoURLEnglish,
                videoURLTamil: contentData.videoURLTamil,
                driveURLEnglish: contentData.driveURLEnglish,
                driveURLSinhala: contentData.driveURLSinhala,
                driveURLTamil: contentData.driveURLTamil,
                isPublished: contentData.isPublished ? 'true' : 'false'
            });
        }
    }, [contentData, formRef, isOpen]);


    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        values.id = contentData.id;

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        setIsLoading(true);

        try {
            const res = await editCourseContent(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Course Content Updated',
                    'New course content updated successfully'
                );
                setOpen(false);
                await hadleGetCourceContents();
            }
        } catch (error) {
            console.error('AddCource', error);
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit Course Content"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className='drawer-header'>
                    <p>
                        Please select languages for edit course content.
                    </p>
                    <Checkbox
                        defaultChecked
                        disabled
                    >
                        English
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                    >
                        Sinhala
                    </Checkbox>
                </div>
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label="Title English"
                            name="titleEnglish" rules={[{ required: true, message: 'Please input the titleEnglish!' }]}>
                            <Input
                                showCount
                                maxLength={200}
                                placeholder="Title English" />
                        </Form.Item>
                        <Form.Item
                            label="Short Description English"
                            name="shortDiscriptionEnglish">
                            <TextArea
                                showCount
                                maxLength={1000}
                                autoSize={{ minRows: 3, maxRows: 10 }}
                                placeholder="Short Description English" />
                        </Form.Item>
                        <Form.Item
                            label="Video URL English"
                            name="videoURLEnglish">
                            <Input
                                showCount
                                maxLength={500}
                                placeholder="Video URL English" />
                        </Form.Item>
                        <Form.Item
                            label="Drive URL English"
                            name="driveURLEnglish">
                            <Input
                                showCount
                                maxLength={500}
                                placeholder="Drive URL English" />
                        </Form.Item>


                        {isSinhalaEnabled &&
                            <>
                                <Divider> Sinhala </Divider>
                                <Form.Item
                                    label="Title Sinhala"
                                    name="titleSinhala">
                                    <Input
                                        showCount
                                        maxLength={200}
                                        placeholder="Title Sinhala" />
                                </Form.Item>
                                <Form.Item
                                    label="Short Description Sinhala"
                                    name="shortDiscriptionSinhala">
                                    <TextArea
                                        showCount
                                        maxLength={1000}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        placeholder="Short Description Sinhala" />
                                </Form.Item>
                                <Form.Item
                                    label="Video URL Sinhala"
                                    name="videoURLSinhala">
                                    <Input
                                        showCount
                                        maxLength={500}
                                        placeholder="Video URL Sinhala" />
                                </Form.Item>
                                <Form.Item
                                    label="Drive URL Sinhala"
                                    name="driveURLSinhala">
                                    <Input
                                        showCount
                                        maxLength={500}
                                        placeholder="Drive URL Sinhala" />
                                </Form.Item>
                            </>
                        }

                        <Divider />
                        <Form.Item
                            label="Is Published"
                            name="isPublished"
                            rules={[{ required: true, message: 'Please check isPublished!' }]}
                        >
                            <Select
                                placeholder="Please select course content published status"
                            >
                                <Option value="true">Published</Option>
                                <Option value="false">Not Published</Option>
                            </Select>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit"
                                >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditContent