import React, { useEffect, useRef, useState } from 'react'

import { notification, Button, Form, Modal, Select, Spin, Drawer, Input, Popconfirm } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setCenterGroups } from '../../../../../../Reducers/centerGroupsReducer';

// API
import { addUsersToGroup, deleteGroup, editGroup, getGroupsByCenterId, getUsersByCenter, getUsersByGroup } from '../../../../../../Constants/Api/functions/function';

import history from '../../../../../../../history';

import GroupMembers from './GroupMembers';

import './style.scss'

const Group = (cneterid, groupid) => {
    const url = window.location.href;
    const [centerId, setCenterId] = useState();
    const [groupId, setGroupId] = useState();
    const [group, setGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [members, setMembers] = useState([])
    const [options, setOptions] = useState([])
    const centerGroups = useSelector((state) => state.centerGroups.centerGroups);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (userProfile?.role === 'student' || userProfile?.role === 'teacher') {
            setCenterId(userProfile?.centerId);
            setGroupId(userProfile?.groupId);
        } else {
            setCenterId(url.split('/')[5] || cneterid);
            setGroupId(url.split('/').pop() || groupid);
        }
    }, [userProfile])

    useEffect(() => {
        if (groupId) {
            if (centerGroups.length > 0) {
                const group = centerGroups.find(group => group.id === groupId);
                setGroup(group);
            } else {
                handleGetGroups();
            }
        }
    }, [centerGroups, groupId])

    useEffect(() => {
        if (formRef.current) {
            onEditFromFill();
        }
    }, [formRef.current, isEditDrawerOpen])

    useEffect(() => {
        if (centerId) {
            handleGetAllUsers();
        }
    }, [centerId])

    useEffect(() => {
        if (groupId) {
            handleGetMembers();
        }
    }, [groupId])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onEditFromFill = () => {
        formRef.current.setFieldsValue({
            name: group?.name,
            leaderId: group?.leaderId
        })
    }

    const handleDelteGroup = async () => {
        try {
            const response = await deleteGroup(groupId);
            if (response) {
                deleteGroupNotification('success');
                setTimeout(() => {
                    history.push(`/dashboard/schools/${centerId}`)
                    window.location.reload();
                }, 2000)
            }
        } catch (error) {
            console.error('deleteGroup', error);
            deleteGroupNotification('error', error);
        }
    }

    const onEditFinish = async (values) => {
        setIsLoading(true)
        values.id = groupId;

        try {
            const response = await editGroup(values);
            if (response) {
                setIsEditDrawerOpen(false);
                editGroupNotification('success');

                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }
        } catch (error) {
            console.error('addUsersToGroup', error);
            editGroupNotification('error', error);
        }
        setIsLoading(false)
    }

    const onFinish = async (values) => {
        setIsLoading(true)

        try {
            const response = await addUsersToGroup(groupId, values.userIds);
            if (response) {
                handleGetMembers();
                handleOk();
                addUsersNotification('success');
            }
        } catch (error) {
            console.error('addUsersToGroup', error);
            addUsersNotification('error', error);
        }
        setIsLoading(false)
    }

    const handleGetAllUsers = async () => {
        setIsLoading(true)
        try {
            const response = await getUsersByCenter(centerId);
            if (response) {
                setOptions(
                    response
                        .filter(user => !user.groupId)
                        .map(user => ({ value: user.id, label: user.name }))
                )
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setIsLoading(false)
    }

    const handleGetMembers = async () => {
        setIsLoading(true)
        try {
            const response = await getUsersByGroup(groupId);
            if (response) {
                setMembers(response);
            }
        } catch (error) {
            console.error('getUsersByGroup', error);
        }
        setIsLoading(false)
    }

    const handleGetGroups = async () => {
        setIsLoading(true)
        try {
            const response = await getGroupsByCenterId(centerId);
            console.log('response', response)
            if (response) {
                dispatch(setCenterGroups(response))
                const group = response.find(group => group.id === groupId);
                setGroup(group);
            }
        } catch (error) {
            console.error('getGroupsCenter', error);
        }
        setIsLoading(false)
    }

    const addUsersNotification = (type, error) => {
        api[type]({
            message: type === "success" ?
                "Users Added"
                : "Error Adding Users",
            description: type === "success"
                ? "Users have been added successfully."
                : `There was an error adding users. Please try again later. ${error}`
        });
    };

    const editGroupNotification = (type, error) => {
        api[type]({
            message: type === "success" ?
                "Group Edited"
                : "Error Editing Group",
            description: type === "success"
                ? "Group has been edited successfully."
                : `There was an error editing group. Please try again later. ${error}`
        });
    }

    const deleteGroupNotification = (type, error) => {
        api[type]({
            message: type === "success" ?
                "Group Deleted"
                : "Error Deleting Group",
            description: type === "success"
                ? "Group has been deleted successfully."
                : `There was an error deleting group. Please try again later. ${error}`
        });
    }

    return (
        <div>
            {contextHolder}
            {isLoading ?
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh'
                    }}
                >
                    <Spin
                        size='large'
                    />
                </div> :
                <div className='group-page'>
                    {group ? <>
                        <div className='lrft'>
                            <div className='header'>
                                <h1>
                                    {group?.name}
                                </h1>
                                {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') &&
                                    <div className='actions'>
                                        <Button
                                            type='default'
                                            onClick={() => {
                                                setIsEditDrawerOpen(true)
                                            }}
                                        >
                                            <EditOutlined />
                                            Edit
                                        </Button>

                                        <Popconfirm
                                            title="Are you sure to delete this group?"
                                            onConfirm={handleDelteGroup}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                                danger
                                                type='default'>
                                                <DeleteOutlined />
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </div>}
                            </div>
                            <p className='id'>
                                Group Id: {group?.id}
                            </p>

                            <div className='details'>
                                <h3>
                                    <span>
                                        Leader:
                                    </span>
                                    <p>
                                        {group?.leaderId
                                            ? members.find(member => member?.id === group.leaderId)?.name
                                            : 'No leader assigned'}
                                    </p>
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className='member-header'>
                                <h3>
                                    Members ({members.length})
                                </h3>
                                {(userProfile?.role === 'superadmin' || userProfile?.role === 'admin') &&
                                    <Button
                                        onClick={showModal}
                                        type='primary'>
                                        Add Members
                                    </Button>
                                }
                            </div>
                            <GroupMembers
                                members={members}
                                handleGetMembers={handleGetMembers}
                            />
                        </div>
                    </>
                        :
                        <div className='no-group'>
                            <h1>
                                You have not assigned to any group yet.
                            </h1>
                        </div>
                    }
                </div>
            }

            <Modal
                title="Add Members to Group"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label='Users'
                        name='userIds'
                        rules={[
                            {
                                required: true,
                                message: 'Please select users'
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select users"
                            options={options}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={isLoading}
                        >
                            Add
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>

            <Drawer
                title="Edit Group"
                placement="right"
                width={720}
                onClose={() => { setIsEditDrawerOpen(false) }}
                open={isEditDrawerOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Loading..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onEditFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            name="name"
                            label="Group Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter group's name",
                                },
                            ]}
                        >
                            <Input placeholder="Please enter group's name" />
                        </Form.Item>

                        <Form.Item
                            name="leaderId"
                            label="Leader"
                        >
                            <Select
                                placeholder="Please select leader"
                            >
                                {
                                    members.map(member => (
                                        <Select.Option value={member.id}>{member.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={isLoading}
                            >
                                Edit
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default Group