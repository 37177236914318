import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// API
import { getAllCenters, getCentersByAdminId } from '../../../Constants/Api/functions/function';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllCenters } from '../../../Reducers/allCentersReducer';

// Antd
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';

import AddSchool from './AddSchool';
import { DISTRICTS } from '../../../Constants/Districts/districts';

const Schools = () => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [centers, setCenters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef(null);
    const dispatch = useDispatch();
    const allCenters = useSelector(state => state.allCenters.allCenters);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const history = useHistory();

    useEffect(() => {
        if (userProfile?.role !== 'superadmin' && userProfile?.role !== 'admin') {
            history.push(`/dashboard/schools/${userProfile?.centerId}`);
        }
    }, [userProfile, history]);

    useEffect(() => {
        if (allCenters?.length) {
            if (userProfile?.role === 'admin') {
                const centerIds = userProfile?.adminsCenterIds?.split(',');
                const adminCenters = allCenters?.filter((center) => centerIds?.includes(center?.id));
                setCenters(adminCenters)
            } else {
                setCenters(allCenters)
            }
        } else {
            if (userProfile?.role === 'admin') {
                handleGetAdminsCenters()
            } else {
                handleGetAllCenters()
            }
        }
    }, [])

    const handleGetAllCenters = async () => {
        setIsLoading(true)
        try {
            const response = await getAllCenters();
            if (response) {
                dispatch(setAllCenters(response))
                setCenters(response)
            }
        } catch (error) {
            console.error('getAllCenters', error);
        }
        setIsLoading(false)
    }

    const handleGetAdminsCenters = async () => {
        setIsLoading(true)
        try {
            const response = await getCentersByAdminId(userProfile?.id);
            if (response) {
                setCenters(response)
            }
        } catch (error) {
            console.error('getCentersByAdminId', error);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (centers?.length) {
            const data = centers?.map((center, index) => {
                return {
                    key: index + 1,
                    id: center?.id,
                    name: center?.name,
                    district: center?.district ? findDistrict(center?.district) : '',
                    contactNumber: center?.contactNumber || 'N/A',
                    address: center?.address || 'N/A'
                }
            })
            setTableData(data)
        }
    }, [centers])

    const findDistrict = (district) => {
        const districtName = DISTRICTS.find((d) => d.val === district);
        return districtName?.name
    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record, key) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/schools/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/schools/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });


    const columns = [
        {
            title: 'School Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'District',
            dataIndex: 'district',
            key: 'district',
            width: '10%',
            ...getColumnSearchProps('district'),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            width: '20%',
            ...getColumnSearchProps('contactNumber'),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '25%',
            ...getColumnSearchProps('address'),
        },
    ];

    return (
        <div className='users'>
            {
                (userProfile?.role === 'superadmin' || userProfile?.role === 'admin')
                &&
                <>
                    <div className='users-action-buttons'>
                        <h2>
                            Schools <Tag
                                style={{
                                    transform: 'translateY(-3px)',
                                    marginLeft: '10px'
                                }}
                                color="blue">{tableData?.length}</Tag>
                        </h2>
                        <Button
                            type='primary'
                            onClick={() => setIsAddUserOpen(true)}
                        >
                            <PlusOutlined />
                            Add School
                        </Button>
                    </div>
                    <Spin
                        spinning={isLoading}
                        tip="Loading Schools..."
                    >
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={{
                                pageSize: 50,
                                position: ["bottomRight"],
                            }}
                        />
                    </Spin>

                    <AddSchool
                        isOpen={isAddUserOpen}
                        visible={isAddUserOpen}
                        setOpen={setIsAddUserOpen}
                        handleGetAllCenters={handleGetAllCenters}
                    />
                </>}
        </div>
    )
}

export default Schools